import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(3),
    width: "100%"
  },
  selectWrapper: {
    width: "100%",
    height: "3.4rem"
  },
  consentStep: {
    whiteSpace: "normal",
    "& .Mui-selected": {
      fontSize: "1.6rem",
      cursor: "pointer",
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    }
  },
  consentStepDisabled: {
    whiteSpace: "normal",
    "& .Mui-disabled": {
      fontSize: "1.6rem",
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      color: theme.palette.pattern.disabledStep,
      cursor: "not-allowed",

    }
  }
}));

export default useStyles;