import { YourInfoHelpButton } from "./YourInfoHelpButton";
import { YourInfoHelpDialog } from "./YourInfotHelpDialog";
import { useState } from "react";

const YourInfoHelp = () => {
  const [ open, setOpen ] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <YourInfoHelpButton onOpen={handleOpen}/>
      <YourInfoHelpDialog isOpened={open} onClose={handleClose}/>
    </>
  );
};

export default YourInfoHelp;