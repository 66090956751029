import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  consentHelpBtn: {
    backgroundColor: theme.palette.pattern.consentHelp,
    color: theme.palette.pattern.blackDialog,
    "&:hover": {
      backgroundColor: theme.palette.pattern.disabledStep
    },
    "& svg": {
      fontSize: "2rem"
    }
  },
  btnWrapper: {
    margin: theme.spacing(1),
    padding: theme.spacing(1),
  },
}));

export default useStyles;