import { Typography } from "@mui/material";
import { useStyles } from "./index";

const AboveTwentyOne = () => {
  const { root, paragraph, bold } = useStyles();

  return (
    <div className={root} data-testid="above-21">
      <Typography paragraph={true} className={paragraph}>
        You must be 21 or older to consent to this study online.
        If you are under 21, please contact the study consent team for information on how to enroll in this study.
      </Typography>
      <Typography paragraph={true} className={`${paragraph} ${bold}`}>
        Phone: 617-582-7843
      </Typography>
      <Typography paragraph={true} className={`${paragraph} ${bold}`}>
        Email: pattern_crc@partners.org
      </Typography>
      <Typography paragraph={true} className={paragraph}>
        No one under 21 will be enrolled online using the electronic consent form
      </Typography>
    </div>
  );
};

export default AboveTwentyOne;