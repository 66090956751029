import * as PropTypes from "prop-types";
import { Grid, Grow, List, ListItem } from "@mui/material";
import { steps, study } from "../propTypes";
import { useStyles } from "./index";

const ConsentStepsDesktop = ({
  steps,
  study,
  maxConsentStep,
  currentConsentStep,
  onStepClick
}) => {

  const {
    img,
    consentStep,
    consentStepDisabled,
  } = useStyles();

  return (
    <Grid
      item
      container
      xs={3}
    >
      <List>
        <ListItem>
          <Grow in={!!study.logoUrl}>
            <img
              className={img}
              src={study.logoUrl}
              alt={`${study.name} logo`}
            />
          </Grow>
        </ListItem>
        {steps.map((step, index) => {
          if (maxConsentStep < index) {
            return (
              <ListItem
                data-testid={`step-${index}`}
                key={index}
                className={consentStepDisabled}
              >
                {step.sequence}. {step.section_title}
              </ListItem>
            );
          } else if (maxConsentStep >= index) {
            return (
              <ListItem
                data-testid={`step-${index}`}
                key={index}
                className={consentStep}
                style={index === currentConsentStep ? { color: "#00aef0"} : {}}
                onClick={() => onStepClick(index)}
              >
                {step.sequence}. {step.section_title}
              </ListItem>
            );
          }
        }
        )}
      </List>
    </Grid>
  );
};

ConsentStepsDesktop.propTypes = {
  study,
  steps,
  maxConsentStep: PropTypes.number,
  currentConsentStep: PropTypes.number,
  onStepClick: PropTypes.func
};

export default ConsentStepsDesktop;