import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  btnWhyDonate: {
    color: theme.palette.pattern.yellow
  },
  btnAboutFaq: {
    color: theme.palette.pattern.green
  },
  btnLogin: {
    width: "9rem"
  }
}));

export default useStyles;