import { Box, Grid, Paper, Typography } from "@mui/material";
import { useStyles } from "./index";

const WhitePanel = () => {

  const { root, title, paragraph } = useStyles();

  return (
    <Paper className={root} id="whyDonate" square={true}>
      <Grid
        item
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        xs={12}
      >
        <Grid item xs={12}>
          <Box mb={5} mt={10}>
            <Typography className={title} variant="h1" align="center">
              Tissue donation drives cancer research
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box mb={15}>
            <Typography className={paragraph} variant="subtitle2">
                By contributing your tumor tissue through a surgery, biopsy, or fluid drain you make it possible
                for researchers to make a working copy of your cancer type that will continually grow in a laboratory.
                This copy is called a &ldquo;model&ldquo; and it makes it easy for researchers to study your cancer
                type and test treatment ideas. Simply put, by sharing your tumor tissue, you make it possible
                for researchers to make faster progress on critical cancer research.
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default WhitePanel;