import { fetchStaticStuff } from "redux/slices/staticStuffSlice";
import {
  selectAbout,
  selectFAQs,
  selectFoundationPartners,
  selectPrivacy, selectTerms
} from "redux/selectors/staticStuffSelector";
import { useDispatch, useSelector } from "react-redux";

export const useStaticStuff = () => {
  const dispatch = useDispatch();

  const getStaticStuff = () => {
    dispatch(fetchStaticStuff());
  };

  const foundationPartners = useSelector(selectFoundationPartners);
  const faqs = useSelector(selectFAQs);
  const privacy = useSelector(selectPrivacy);
  const about = useSelector(selectAbout);
  const terms = useSelector(selectTerms);


  return {
    getStaticStuff,
    foundationPartners,
    faqs,
    privacy,
    about,
    terms
  };
};