import { ConsentContainer } from "./Consent";
import { DonationInfoContainer } from "./DonationInfo";
import { Grid } from "@mui/material";
import { StudyInfoContainer } from "./StudyInfo";
import { YourInfoContainer } from "./YourInfo";
import PropTypes from "prop-types";

export const EnrollmentView = ({ currentEnrollmentStep }) => (
  <Grid container direction="column" p={3} style={{ backgroundColor: "white"}}>
    { currentEnrollmentStep === 0 && <YourInfoContainer/> }
    { currentEnrollmentStep === 1 && <StudyInfoContainer/> }
    { currentEnrollmentStep === 2 && <ConsentContainer/> }
    { currentEnrollmentStep === 3 && <DonationInfoContainer/> }
  </Grid>
);

EnrollmentView.propTypes = {
  currentEnrollmentStep: PropTypes.number
};