import { combineReducers } from "redux";
import authSlice from "redux/slices/authSlice";
import cancersSlice from "redux/slices/cancersSlice";
import consentSlice from "redux/slices/consentSlice";
import donationInfoSlice from "./slices/donationInfoSlice";
import enrollmentSlice from "redux/slices/enrollmentSlice";
import foundationPartnersSlice from "./slices/foundationPartnersSlice";
import staticStuffSlice from "./slices/staticStuffSlice";
import studyInfoSlice from "redux/slices/studyInfoSlice";
import userSlice from "redux/slices/userSlice";

const combinedReducer = combineReducers({
  enrollment: enrollmentSlice,
  cancers: cancersSlice,
  foundationPartners: foundationPartnersSlice,
  user: userSlice,
  auth: authSlice,
  studyInfo: studyInfoSlice,
  consent: consentSlice,
  donationInfo: donationInfoSlice,
  staticStuff: staticStuffSlice
});

export default combinedReducer;