import { createSelector } from "@reduxjs/toolkit";

export const userSelector = state => state.user;

export const selectCancers = createSelector(
  userSelector,
  user => user.user
);

export const selectStatus = createSelector(
  userSelector,
  user => user.status
);

export const selectUserError = createSelector(
  userSelector,
  user => user.error
);

export const selectUserData = createSelector(
  userSelector,
  user => user.user?.data
);