import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  arrow: {
    color: "white",
    position: "absolute",
    bottom: 0,
    left: "50%",
    transform: "translateX(-50%)"
  },
  arrowIcon: {
    fontSize: "6rem"
  }
});

export default useStyles;