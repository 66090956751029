import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  donationInfo: null,
  status: "idle",
  error: null
};

const donationInfoSlice = createSlice({
  name: "donationInfo",
  initialState,
  reducers: {
    saveDonationInfo() {},
    pending(state) {
      state.status = "loading";
    },
    success(state, action) {
      state.status = "succeeded";
      state.donationInfo = action.payload;
    },
    failure(state, action) {
      state.status = "failed";
      state.error = action.payload;
    }
  }
});

export const {
  saveDonationInfo,
  pending,
  success,
  failure
} = donationInfoSlice.actions;
export default donationInfoSlice.reducer;