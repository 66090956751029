import { useLocation, useNavigate } from "react-router-dom";
import LandingPage from "../../pages/LandingPage";
import loadable from "@loadable/component";

export const useAppLocation = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const EnrollmentPage = loadable(() => import("pages/EnrollmentPage"));
  const WhyDonatePage = loadable(() => import("pages/WhyDonatePage"));
  const AboutPage = loadable(() => import("pages/AboutPage"));
  const FaqPage = loadable(() => import("pages/FaqPage"));
  const TermsPage = loadable(() => import("pages/TermsPage"));
  const PrivacyPage = loadable(() => import("pages/PrivacyPage"));
  const LoginPage = loadable(() => import("pages/LoginPage"));
  const DashboardPage = loadable(() => import("pages/DashboardPage"));
  const SuccessPage = loadable(() => import("pages/SuccessPage"));


  const getPages = () => ([
    { path: "/", pageComponent: <LandingPage/>},
    { path: "/enrollment", pageComponent: <EnrollmentPage/>},
    { path: "/whyDonate", pageComponent: <WhyDonatePage/>},
    { path: "/about", pageComponent: <AboutPage/>},
    { path: "/faq", pageComponent: <FaqPage/>},
    { path: "/terms", pageComponent: <TermsPage/>},
    { path: "/privacy", pageComponent: <PrivacyPage/>},
    { path: "/login", pageComponent: <LoginPage/>},
    { path: "/dashboard", pageComponent: <DashboardPage/>},
    { path: "/success", pageComponent: <SuccessPage/>}
  ]);

  const isRoot = pathname === "/";
  const isLogin = pathname === "/login";
  const isEnrollmentPage = pathname === "/enrollment";
  const isDashboardPage = pathname === "/dashboard";
  const isWhyDonatePage = pathname === "/whyDonate";
  const isAboutPage = pathname === "/about";
  const isFaqPage = pathname === "/faq";
  const isSuccessPage = pathname === "/success";

  const navigateTo = (path) => navigate(path);

  return {
    isRoot,
    isLogin,
    isEnrollmentPage,
    isDashboardPage,
    isWhyDonatePage,
    isAboutPage,
    isFaqPage,
    isSuccessPage,
    pathname,
    navigateTo,
    getPages
  };
};