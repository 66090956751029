const { makeStyles } = require("@mui/styles");

const useStyles = makeStyles(theme => ({
  formWidth: {
    width: "50%"
  },
  formField: {
    marginBottom: theme.spacing(3)
  },
  tooltip: {
    color: theme.palette.pattern.gray,
    cursor: "pointer",
    "& svg": {
      fontSize: "2rem"
    }
  }
}));

export { useStyles };