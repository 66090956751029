import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexFlow: "column",
    height: "100vh",
    backgroundColor: "white"
  }
});

export default useStyles;