import { createSelector } from "@reduxjs/toolkit";

const donationInfoSelector = state => state.donationInfo;

export const selectDonationInfo = createSelector(
  donationInfoSelector,
  donationInfo => donationInfo.donationInfo
);

export const selectDonationInfoStatus = createSelector(
  donationInfoSelector,
  donationInfo => donationInfo.status
);