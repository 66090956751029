import { createSelector } from "@reduxjs/toolkit";

const studyInfoSelector = state => state.studyInfo;

export const selectStudyInfo = createSelector(
  studyInfoSelector,
  studyInfo => studyInfo.studyInfo
);

export const selectStudyInfoStatus = createSelector(
  studyInfoSelector,
  studyInfo => studyInfo.status
);