import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    backgroundColor: theme.palette.pattern.white
  },
  title: {
    fontSize: "2rem",
    fontWeight: 500,
    color: theme.palette.pattern.white,
    backgroundColor: theme.palette.pattern.blue
  },
  imgContainer: {
    position: "relative",
    height: "100%"
  },
  img: {
    width: "85%"
  },
  imgLogo: {
    width: "50vw"
  },
  imgRight: {
    position: "absolute",
    bottom: 0,
    right: 0
  },
  loginForm: {
    minWidth: "80%"
  }
}));

export default useStyles;