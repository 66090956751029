import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: "15rem",
    paddingRight: "15rem",
    [theme.breakpoints.down("md")]: {
      paddingLeft: "2rem",
      paddingRight: "2rem",
    }
  },
  title: {
    color: theme.palette.pattern.blue,
    fontSize: "3.4rem",
    fontWeight: 600
  },
  paragraph: {
    fontSize: "2rem",
    fontWeight: 500
  }
}));

export default useStyles;