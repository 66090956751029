import { LOGIN, PASSCODE, REST_USER } from "shared/constants/endpoints";
import axios from "axios";

export class UserService {
  static async getUser() {
    try {
      const res = await axios.get(REST_USER);
      return res.data;
    } catch (error) {
      throw error;
    }
  }

  static async saveUser(userData) {
    try {
      const res = await axios.post(REST_USER, userData);
      return res.data;
    } catch (error) {
      throw error;
    }
  }

  static async patchUser(userData) {
    try {
      const res = await axios.patch(REST_USER, userData);
      return res.data;
    } catch (error) {
      throw error;
    }
  }

  static async getPasscode(email) {
    try {
      const res = await axios.post(PASSCODE, { email, website: "pattern"});
      return res.data;
    } catch (error) {
      throw error;
    }
  }

  static async loginUser(email, passcode) {
    try {
      const res = await axios.post(LOGIN, { email, passcode, website: "pattern"});
      return res.data;
    } catch (error) {
      throw error;
    }
  }
}