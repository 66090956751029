import { useAppLocation, useUser } from "../../hooks";
import { useEffect } from "react";

const AuthGuard = ({ children }) => {

  const { sessionPersonalInfo } = useUser();
  const { isSuccessPage, isDashboardPage, navigateTo, pathname } = useAppLocation();

  useEffect(() => {
    if ((isSuccessPage || isDashboardPage) && !sessionPersonalInfo) {
      navigateTo("/");
    }
  }, [pathname]);
  return children;
};

export default AuthGuard;