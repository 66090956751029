import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(5)
  },
  stepImages: {
    marginBottom: theme.spacing(6)
  },
  avatar: {
    width: "125px",
    [theme.breakpoints.down("lg")]: {
      marginRight: theme.spacing(3)
    }
  },
  avatarSubtitle: {
    color: theme.palette.pattern.white,
    fontSize: "2rem",
    fontWeight: 700,
    lineHeight: "3rem"
  }
}));

export default useStyles;