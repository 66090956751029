import { GET_CANCERS } from "shared/constants/endpoints";
import axios from "axios";

export class EnrollmentService {
  static async getCancers() {
    try {
      const res = await axios.get(GET_CANCERS);
      return res.data;
    } catch (error) {
      throw error;
    }
  }
}