import { StudyInfoService } from "services/studyInfoService";
import { call, put } from "redux-saga-test-plan/matchers";
import {
  failure,
  pending,
  success
} from "redux/slices/studyInfoSlice";
import { getStudyInfo } from "redux/slices/studyInfoSlice";
import { takeLatest } from "redux-saga/effects";

export const studyInfoSaga = [
  takeLatest(getStudyInfo, handleFetchStudyInfo),
];

function* handleFetchStudyInfo({ payload }) {

  const { cancer_id } = payload;

  try {
    yield put(pending());
    const response = yield call(StudyInfoService.getStudyList, cancer_id);
    yield put(success(response));
  } catch (error) {
    yield put(failure(error));
  }
}