import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex"
  },
  burger: {
    [theme.breakpoints.down("sm")]: {
      paddingTop: 0,
      paddingBottom: 0
    }
  },
  btn: {
    borderRadius: 0,
  },
  btnWhyDonate: {
    color: theme.palette.pattern.yellow
  },
  btnAboutFaq: {
    color: theme.palette.pattern.green
  }
}));

export default useStyles;