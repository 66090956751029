import { Grid } from "@mui/material";
import {
  LandingHome
} from "components";

const LandingPage = () => {

  return (
    <Grid
      container
      item
      direction="column"
      alignContent="center"
    >
      <LandingHome />
    </Grid>
  );
};

export default LandingPage;