import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: "3.4rem",
    fontWeight: 400,
    color: theme.palette.pattern.blue
  }
}));

export default useStyles;