import { Box, Button, Grid, Paper } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { useStyles } from "./index";
import { useUser } from "../../../shared/hooks";

const CtaFooter = () => {

  const { root } = useStyles();

  const { sessionPersonalInfo} = useUser();

  return (
    <Paper className={root} square={true}>
      <Grid item container xs={12} justifyContent="center">
        <Grid item xs={11} md={5}>
          <Box mt={5} mb={3}>
            <Button
              color="secondary"
              variant="contained"
              fullWidth
              xt={5}
              component={RouterLink}
              to="/enrollment"
            >
              { sessionPersonalInfo ? "Continue Enrollment" : "Get Started" }
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default CtaFooter;