import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.pattern.white
  },
  copyright: {
    fontSize: "1.6rem",
    fontWeight: 400,
    color: theme.palette.pattern.gray
  },
  registered: {
    fontSize: "1.2rem",
    fontWeight: 400,
    color: theme.palette.pattern.gray
  }
}));

export default useStyles;