import * as yup from "yup";

export const PHONE_PLACEHOLDER = "(___) ___-____";
export const MASK = "(999) 999-9999";

export const personalInfoSchema = yup.object().shape({
  first_name: yup.string().required("Please enter your name"),
  last_name: yup.string().required("Please enter your last name"),
  phone_number: yup.string()
    .required()
    .test(phoneNumber => validatePhoneNumber(phoneNumber)),
  email: yup.string()
    .email("You must enter a valid email.")
    .max(100)
    .required("Email is required"),
  email2: yup.string()
    .email("You must enter a valid email.")
    .max(100)
    .oneOf([yup.ref("email"), null], "Your emails do not match.")
    .required("Email is required"),
  cancer_diagnosis: yup.number().required(),
  site: yup.string(),
  occurrence_type: yup.string(),
  primary_occurrence_location_for_metastatic: yup.string(),
  country: yup.string().required(),
  mailing_address_1: yup.string(),
  mailing_address_2: yup.string(),
  city: yup.string(),
  state: yup.string(),
  postcode: yup.string().test(postcode => postcode.length === 0 || postcode.length >= 5)
});

export const screeningSchema = yup.object().shape({
  email: yup.string()
    .email("You must enter a valid email.")
    .max(100)
    .required("Email is required"),
  tissueExists: yup.string().required(),
  countryTissue: yup.string().when("tissueExists", {
    is: "Yes",
    then: () => yup.string().required()
  })
});

export const donationInfoSchema = yup.object().shape({
  surgery_date: yup.string(),
  name: yup.string().required(),
  city: yup.string().required(),
  state: yup.string().required(),
  birthday: yup.date()
    .min(new Date("01/18/1902"),"Choose a birth date on or after 01/18/1902")
    .max(new Date(), "Choose a birth date on or before today")
    .typeError("Invalid date format. It should be MM/DD/YYYY")
    .required("Your birthdate is required")
});

export const consentSchema = yup.object().shape({
  fullName: yup.string().required(),
  birthday: yup.date()
    .min(new Date("01/18/1902"),"Choose a birth date on or after 01/18/1902")
    .max(new Date(), "Choose a birth date on or before today")
    .typeError("Invalid date format. It should be MM/DD/YYYY")
    .required("Your birthdate is required")
});

export const contactInfoSchema = yup.object().shape({
  first_name: yup.string().required("Please enter your name"),
  last_name: yup.string().required("Please enter your last name"),
  email: yup.string()
    .email("You must enter a valid email.")
    .max(100)
    .required("Email is required")
});

export const loginFormEmailSchema = yup.object().shape({
  email: yup.string()
    .email("You must enter a valid email.")
    .max(100)
    .required("Email is required"),
});

export const loginFormPasscodeSchema = yup.object().shape({
  passcode: yup.number()
    .required("You must enter a passcode.")
    .typeError("You must enter a passcode.")
    .test("len", "Must be exactly 6 characters", val => {
      if (val) {
        return val.toString().length === 6;
      }
    })
});

export const defaultContactInfoValues = {
  first_name: "",
  last_name: "",
  phone_number: "",
  email: "",
};

export const defaultPersonalInfoValues = {
  first_name: "",
  last_name: "",
  phone_number: "",
  email: "",
  email2: "",
  cancer_diagnosis: "",
  site: "",
  occurrence_type: "",
  primary_occurrence_location_for_metastatic: "",
  country: "",
  mailing_address_1: "",
  mailing_address_2: "",
  city: "",
  state: "",
  postcode: ""
};

export const defaultDonationInfoValues = {
  surgery_date: "",
  city: "",
  name: "",
  state: "",
  surgeon: "",
  surgeon_phone: "",
  oncologist: "",
  oncologist_phone: "",
  birthday: null,
  selectedRaces: [],
  sex: ""
};

export const parsePhoneNumber = (unparsed) => {
  if (!unparsed) return;
  const unwantedChars = ["(", ")", "-", " ", "_"];
  return unparsed.split("").filter(char => !unwantedChars.includes(char)).join("");
};

const validatePhoneNumber = (phoneNumber) => {
  if (!phoneNumber) return;
  return parsePhoneNumber(phoneNumber).length === 10;
};

export const isEnrollmentPersonalInfoFormInvalid = (isValid, cancerDiagnosis, email, email2, phoneNumber ) => {
  return !isValid ||
      cancerDiagnosis === 0 ||
      email !== email2 ||
      !phoneNumber ||
      phoneNumber === PHONE_PLACEHOLDER ||
      phoneNumber.includes("_");
};

export const serializeUserData = (userData, cancers) => ({
  complete: true,
  qs_params: {},
  ...userData,
  phone_number: parsePhoneNumber(userData.phone_number),
  cancer_diagnosis: cancers.find(cancer => cancer.id === userData.cancer_diagnosis)
});

export const serializePatchUserData = (userData) => {
  const entries = Object.entries(userData);
  const serialized = entries.map(entry => {
    return { op: "replace", path: `/${entry[0]}`, value: entry[1] };
  });
  return serialized;
};

export const serializeDonationInfo = (donationInfo, id) => ({
  website: "pattern",
  study_id: id,
  hospital: {
    name: donationInfo.name,
    city: donationInfo.city,
    state: donationInfo.state
  },
  selectedRaces: donationInfo.selectedRaces,
  birthday: donationInfo.birthday,
  surgeon: donationInfo.surgeon,
  surgeon_phone: donationInfo.surgeon_phone,
  oncologist: donationInfo.oncologist,
  oncologist_phone: donationInfo.oncologist_phone,
  sex: donationInfo.sex,
  surgery_date: donationInfo.surgery_date
});

export const isDonationInfoFormInvalid = (isValid, surgeonPhone, oncologistPhone) => {
  return !isValid ||
      surgeonPhone !== PHONE_PLACEHOLDER &&
      surgeonPhone.includes("_") ||
      oncologistPhone !== PHONE_PLACEHOLDER &&
      oncologistPhone.includes("_");
};

export const isContactInfoFormInvalid = (isValid, phoneNumber) => {
  return !isValid ||
      !phoneNumber ||
      phoneNumber === PHONE_PLACEHOLDER ||
      phoneNumber.includes("_");
};