import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  stepHeader: {
    fontSize: "2.4rem",
    fontWeight: 400,
    color: theme.palette.pattern.blue,
    margin: "1rem auto"
  },
  consentText: {
    fontSize: "2rem",
    fontWeight: 400,
    "& a": {
      textDecoration: "none",
      color: theme.palette.pattern.secondaryBg,
      "&:hover": {
        textDecoration: "underline"
      }
    }
  }
}));

export default useStyles;