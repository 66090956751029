import { selectNonEligibleDialogState } from "redux/selectors/enrollmentSelector";
import {
  toggleNonEligibleDialog,
  toggleVisibilityPersonalInfo,
  toggleVisibilityScreening
} from "redux/slices/enrollmentSlice";
import { useDispatch, useSelector } from "react-redux";

export const useEnrollmentDialog = () => {

  const dispatch = useDispatch();

  const isNonEligibleDialogOpen = useSelector(selectNonEligibleDialogState);
  const setNonEligibleDialog = toggle => dispatch(toggleNonEligibleDialog(toggle));
  const setVisibilityScreening = toggle => dispatch(toggleVisibilityScreening(toggle));
  const setVisibilityPersonalInfo = toggle => dispatch(toggleVisibilityPersonalInfo(toggle));

  return {
    isNonEligibleDialogOpen,
    setNonEligibleDialog,
    setVisibilityScreening,
    setVisibilityPersonalInfo
  };
};