import { Box, Card, CardContent, Grid, Typography } from "@mui/material";
import { useEffect } from "react";
import { useStaticStuff } from "../../shared/hooks";
import { useStyles } from "./index";
import Parser from "html-react-parser";

const Faq = () => {

  const { root, title, item, header, link } = useStyles();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { faqs } = useStaticStuff();

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
      className={root}
    >
      <Grid
        container
        alignItems="center"
        justifyContent="center"
      >
        <Grid  item container
          xs={11}
          alignItems="center"
          justifyContent="flex-start"
          spacing={5}>
          <Grid item className={item}>
            <Typography className={title} align="center">
              Frequently Asked Questions
            </Typography>
          </Grid>
          {
            faqs && faqs.map((faq, index) => {
              return (
                <Grid key={index } item className={item}>
                  <Card>
                    <Box className={header}><span>Q: </span>{Parser(faq.question)}</Box>
                    <CardContent>
                      <Box className={link}>
                        {Parser(faq.answer)}
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
              );
            })
          }
        </Grid>
      </Grid>

    </Grid>
  );
};

export default Faq;
