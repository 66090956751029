import { Box, FormControlLabel, Grid, Radio, RadioGroup, Typography } from "@mui/material";
import { Controller } from "react-hook-form";
import { useStyles } from "./PatternRadioQuestion.styles";
import PropTypes from "prop-types";

export const PatternRadioQuestion = ({ top, control, title, field, testId }) => {

  const {
    radioGroup
  } = useStyles();

  return (
    <Grid item container justifyContent="center" alignItems="center">
      <Grid item xs={12} md={8}>
        <Box mt={top} mb={1}>
          <Typography variant="subtitle1" align="center">
            {title}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={8} md={6}>
        <Controller
          name={field}
          isClearable
          control={control}
          render={({ field }) =>
            <RadioGroup
              aria-label={field}
              name={field}
              row
              className={radioGroup}
              {...field}
            >
              <FormControlLabel
                value="Yes"
                control={
                  <Radio
                    color="primary"
                    required
                    inputProps={{
                      "data-testid": `${testId}-yes`
                    }}
                  />}
                label="Yes"
              />
              <FormControlLabel
                value="No"
                control={
                  <Radio
                    inputProps={{
                      "data-testid": `${testId}-no`
                    }}
                    color="primary"
                    required
                  />}
                label="No"
              />
            </RadioGroup>
          }
        />
      </Grid>
    </Grid>
  );
};

PatternRadioQuestion.propTypes = {
  top: PropTypes.number,
  control: PropTypes.object,
  title: PropTypes.string,
  field: PropTypes.string,
  testId: PropTypes.string
};