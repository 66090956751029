import * as PropTypes from "prop-types";
import { Box, Grid, Typography } from "@mui/material";
import { ConsentActions } from "./ConsentActions";
import { ConsentFinalStepForm } from "./ConsentFinalStepForm";
import { steps } from "../propTypes";
import { useStyles } from "./index";

const ConsentText = ({
  steps,
  consentStudyName,
  currentConsentStep,
  onClickUnderstand,
  onClickUnderstandLastStep,
  onContactInfoOpen
}) => {

  const {
    stepHeader,
    consentText,
  } = useStyles();

  const isLastStep = currentConsentStep === steps.length - 1;

  return (
    <Grid
      item
      container
      xs={12}
      md={9}
    >
      {steps.map((step, index) => {
        if (index === currentConsentStep) return (
          <Grid item container key={index}>
            <Typography
              className={stepHeader}
              variant="h2"
            >
              {step.sequence}. {step.section_title}
            </Typography>

            <Grid item xs={12}>
              {step.text.map((textLine, index) => (
                <Box
                  key={index}
                  mt={2}
                  mb={2}
                  className={consentText}
                  dangerouslySetInnerHTML={{__html: textLine}}
                >
                </Box>
              ))}
            </Grid>

            {isLastStep && (
              <Grid
                item
                container
                xs={12}
                justifyContent="center"
              >
                <ConsentFinalStepForm
                  consentStudyName={consentStudyName}
                  acceptButtonText={step.accept_button_text}
                  onOpen={onContactInfoOpen}
                  onSubmit={onClickUnderstandLastStep}
                />
              </Grid>
            )}

            {!isLastStep && (
              <ConsentActions
                onClickUnderstand={onClickUnderstand}
                currentConsentStep={currentConsentStep}
                step={step}
                onOpen={onContactInfoOpen}
              />
            )}

          </Grid>
        );
      })}
    </Grid>
  );
};

ConsentText.propTypes = {
  steps,
  consentStudyName: PropTypes.string,
  currentConsentStep: PropTypes.number,
  onClickUnderstand: PropTypes.func,
  onClickUnderstandLastStep: PropTypes.func,
  onContactInfoOpen: PropTypes.func
};

export default ConsentText;