import { GET_CONSENT } from "shared/constants/endpoints";
import axios from "axios";

export class ConsentService {
  static async getConsent(study_id) {
    try {
      const res = await axios.get(GET_CONSENT, { params: { study_id } });
      return res.data;
    } catch (error) {
      throw error;
    }
  }
}