import { ArrowButton } from "shared/components";
import { Grid, Hidden } from "@mui/material";
import { JumbotronCard, useStyles } from "./index";
import { Paper } from "@mui/material";

const Jumbotron = () => {

  const { root, jumbotronCard } = useStyles();

  return (
    <Paper className={root} square>
      <Grid
        item
        container
        xs={12}
        justifyContent="space-evenly"
        alignItems="center"
        className={jumbotronCard}
      >
        <JumbotronCard/>
        <Hidden mdDown>
          <Grid item xs={5}></Grid>
        </Hidden>
      </Grid>
      <Grid item>
        <ArrowButton
          to="whyDonate"
          position="absolute"
          color="white"
        />
      </Grid>
    </Paper>
  );

};

export default Jumbotron;