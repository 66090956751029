import { AppBar, Box, Grid, Hidden, Link, Toolbar } from "@mui/material";
import { EnrollmentStepper } from "../index";
import { HeaderMenuDesktop, HeaderMenuMobile, useStyles } from "./index";
import { Link as RouterLink } from "react-router-dom";
import { useAppLocation, useUser } from "shared/hooks";
import Logo from "assets/images/logo-pattern.svg";

const Header = () => {

  const { root, logo } = useStyles();

  const { isEnrollmentPage, isDashboardPage, isWhyDonatePage, isAboutPage, isFaqPage } = useAppLocation();
  const { logoutUser, sessionPersonalInfo } = useUser();

  const handleLogout = () => logoutUser();

  return <>
    <AppBar position="fixed" className={root}>
      <Toolbar>
        <Grid
          item
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item xs={4}>
            <Box className={logo}>
              <Link component={RouterLink} to="/">
                <img src={Logo} alt="logo-pattern"/>
              </Link>
            </Box>
          </Grid>
          <Hidden mdDown>
            <Grid
              item
              container
              justifyContent="flex-end"
              xs={8}
              spacing={5}
            >
              <HeaderMenuDesktop
                isDashboardPage={isDashboardPage}
                isEnrollmentPage={isEnrollmentPage}
                sessionPersonalInfo={sessionPersonalInfo}
                isFaqPage={isFaqPage}
                isAboutPage={isAboutPage}
                isWhyDonatePage={isWhyDonatePage}
                onLogout={handleLogout}
              />
            </Grid>
          </Hidden>
          <Hidden mdUp>
            <Grid
              item
              container
              justifyContent="flex-end"
              xs={8}
            >
              <HeaderMenuMobile
                isDashboardPage={isDashboardPage}
                isEnrollmentPage={isEnrollmentPage}
                sessionPersonalInfo={sessionPersonalInfo}
                isFaqPage={isFaqPage}
                isAboutPage={isAboutPage}
                isWhyDonatePage={isWhyDonatePage}
                onLogout={handleLogout}
              />
            </Grid>
          </Hidden>
        </Grid>
      </Toolbar>
      <EnrollmentStepper isEnrollmentPage={isEnrollmentPage}/>
    </AppBar>
  </>;
};

export default Header;