import * as PropTypes from "prop-types";
import { Box, Button, Grid, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { SuccessCard, useStyles } from "./index";

const SuccessView = ({ consent }) => {
  const {
    title,
    paragraph
  } = useStyles();

  return (
    <Grid
      item
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
    >
      <Grid
        item
        container
        justifyContent="center"
        alignItems="center"
        xs={12}
        md={8}
      >
        <Grid item xs={12} container justifyContent="center">
          <Box mt={3} mb={3}>
            <Typography variant="h1" className={title}>
              You&#39;re Enrolled!
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={10} md={12}>
          <Box mb={3}>
            <Typography variant="h3" align="center" className={paragraph}>
              Thank you for joining this important research study.
              Your tumor tissue will enable leading researchers to advance our understanding of cancer.
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Grid
        item
        container
        justifyContent="center"
        alignItems="center"
      >
        <Grid
          item
          container
          xs={11}
          md={8}
        >
          <SuccessCard consent={consent} />
        </Grid>
      </Grid>
      <Box mt={4} mb={2}>
        <Button
          variant="contained"
          color="primary"
          component={RouterLink}
          to="/dashboard"
        >
          Go to my account
        </Button>
      </Box>

    </Grid>
  );
};

SuccessView.propTypes = {
  consent: PropTypes.object
};

export default SuccessView;