import { TextField } from "@mui/material";
import { withStyles } from "@mui/styles";

const PatternTextField = withStyles({
  root: {
    "& label, & span, & p": {
      fontWeight: 500
    },
  },
})(TextField);

export default PatternTextField;