import * as PropType from "prop-types";
import {
  Box,
  Grid,
  Typography
} from "@mui/material";
import { DonationInfoForm } from "./DonationInfoForm";
import { useStyles } from "../StudyInfo";

const DonationInfoView = ({ onSubmitDonationInfoForm, birthday, consentStudyName }) => {

  const { title, paragraph } = useStyles();

  return (
    <Grid
      item
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
    >
      <Grid
        item
        container
        justifyContent="center"
        alignItems="center"
        xs={10}
        md={12}
      >
        <Grid
          item
          xs={10}
          md={12}
        >
          <Box mt={3} mb={3}>
            <Typography variant="h1" className={title} align="center">
                Donation Information
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={8}>
          <Box mb={3}>
            <Typography variant="h1" className={paragraph} align="center">
              This information will help us arrange you donation. Please include as much information as you are able.
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <DonationInfoForm birthday={birthday} onSubmit={onSubmitDonationInfoForm} consentStudyName={consentStudyName}/>
    </Grid>
  );
};

DonationInfoView.propTypes = {
  onSubmitDonationInfoForm: PropType.func,
  birthday: PropType.instanceOf(Date),
  consentStudyName: PropType.string
};

export default DonationInfoView;