import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  card: {
    padding: theme.spacing(2),
    margin: theme.spacing(2),
    "& ul": {
      paddingLeft: 0
    }
  },
  cardContent: {
    fontSize: "1.6rem"
  }
}));

export default useStyles;