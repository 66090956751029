import { AboveTwentyOne } from "../../index";
import {
  Box,
  Button,
  Grid,
  MenuItem,
  Typography
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import {
  PatternDatePicker,
  PatternMultiSelect,
  PatternPhoneField,
  PatternTextField
} from "shared/components";
import { PatternSelect } from "shared/components/PatternSelect";
import {
  defaultDonationInfoValues,
  donationInfoSchema,
  isDonationInfoFormInvalid
} from "shared/utils/formUtils";
import { getAllStatesAndProvinces, races } from "shared/constants/arrays";
import { parseISO } from "date-fns";
import { useEffect } from "react";
import { useStyles } from "./index";
import { useUser } from "shared/hooks";
import { yupResolver } from "@hookform/resolvers/yup";
import PatternPrompt from "shared/components/PatternPrompt/PatternPrompt";
import PropTypes from "prop-types";

const DonationInfoForm = ({ onSubmit, birthday, consentStudyName }) => {

  const {
    formWidth,
    formField,
    subtitle,
    birthdayLabel
  } = useStyles();

  const methods = useForm({
    defaultValues: defaultDonationInfoValues,
    mode: "all",
    resolver: yupResolver(donationInfoSchema)
  });

  const {
    handleSubmit,
    control,
    setValue,
    setError,
    clearErrors,
    watch,
    formState: { isValid, errors, isDirty, isSubmitting }
  } = methods;

  useEffect(() => {
    if (birthday) {
      setValue("birthday", parseISO(birthday));
    }
  }, [birthday]);

  const surgeonPhone = watch("surgeon_phone");
  const oncologistPhone = watch("oncologist_phone");
  const selectedRaces = watch("selectedRaces");

  const getRaces = () => {
    if (selectedRaces.length && selectedRaces.includes("Prefer not to answer")) {
      const newSelectedRaces = races.map(race => {
        return race.text !== "Prefer not to answer" ?
          { text: race.text, disabled: true } : { text: race.text, disabled: false};
      });

      return newSelectedRaces;
    } else {
      return races;
    }
  };

  const handleSelect = (value, name) => {
    if (value.length && value.includes("Prefer not to answer")) {
      setValue(name, [ "Prefer not to answer" ]);
    } else {
      return setValue(name, value.filter(val => val !== "Prefer not to answer"));
    }
  };

  const { leavePage } = useUser();

  return (
    <Grid
      item
      container
      xs={12}
      md={8}
      justifyContent="center"
    >
      <PatternPrompt
        when={isDirty && !isSubmitting}
        message={"You are about to leave the enrollment process. Data will be lost."}
        onProceed={leavePage}
      />
      <form
        className={formWidth}
        onSubmit={handleSubmit(onSubmit)}
        autoComplete="off"
        noValidate
      >
        <Grid
          item
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          <Grid item container xs>
            <Typography
              variant="h2"
              className={subtitle}
              align="left"
            >
              Hospital and Procedure Information
            </Typography>
          </Grid>

          {/* Surgery Date */}
          <Grid item container className={formField}>
            <Controller
              name="surgery_date"
              control={control}
              render={({ field }) =>
                <PatternTextField
                  variant="standard"
                  color="primary"
                  label="Surgery/Fluid/Biopsy Date"
                  fullWidth
                  inputProps={{
                    "data-testid": "surgery_date"
                  }}
                  {...field}
                />
              }
            />
          </Grid>

          {/* Hospital Name */}
          <Grid item container className={formField}>
            <Controller
              name="name"
              control={control}
              render={({ field }) =>
                <PatternTextField
                  variant="standard"
                  color="primary"
                  label="Hospital Name"
                  fullWidth
                  error={!!errors.name}
                  helperText={errors.name ? "Please enter your hospital name" : ""}
                  required
                  inputProps={{
                    "data-testid": "name"
                  }}
                  {...field}
                />
              }
            />
          </Grid>

          <Grid item container justifyContent="space-between">
            {/* Hospital City */}
            <Grid item container className={formField} xs={12} md={6}>
              <Controller
                name="city"
                control={control}
                render={({ field }) =>
                  <PatternTextField
                    variant="standard"
                    color="primary"
                    label="Hospital City"
                    fullWidth
                    error={!!errors.city}
                    helperText={errors.city ? "Please enter your hospital city" : ""}
                    required
                    inputProps={{
                      "data-testid": "city"
                    }}
                    {...field}
                  />
                }
              />
            </Grid>

            {/* Hospital State / Province */}
            <Grid item container className={formField} xs={12} md={5}>
              <PatternSelect
                control={control}
                defaultValue=""
                name="state"
                label={"Hospital State/Province"}
                required
                fullWidth
                error={!!errors.state}
                testId={"state"}
                helperText={errors.state ? "You must select a state or province." : ""}
              >
                {getAllStatesAndProvinces().map((state, index) =>
                  <MenuItem
                    key={index}
                    value={state}>
                    {state}
                  </MenuItem>
                )}
              </PatternSelect>
            </Grid>
          </Grid>

          {/* Surgeon Name */}
          <Grid item container className={formField}>
            <Controller
              name="surgeon"
              control={control}
              render={({ field }) =>
                <PatternTextField
                  variant="standard"
                  color="primary"
                  label="Surgeon Name"
                  fullWidth
                  inputProps={{
                    "data-testid": "surgeon"
                  }}
                  {...field}
                />
              }
            />
          </Grid>

          {/* Surgeon Phone */}
          <Grid item container className={formField}>
            <PatternPhoneField
              name="surgeon_phone"
              label="Surgeon Phone Number"
              control={control}
              setValue={setValue}
              setError={setError}
              clearErrors={clearErrors}
              testId={"surgeon_phone"}
            />
          </Grid>

          {/* Oncologist Name */}
          <Grid item container className={formField}>
            <Controller
              name="oncologist"
              control={control}
              render={({ field }) =>
                <PatternTextField
                  variant="standard"
                  color="primary"
                  label="Oncologist Name"
                  fullWidth
                  inputProps={{
                    "data-testid": "oncologist"
                  }}
                  {...field}
                />
              }
            />
          </Grid>

          {/* Oncologist Phone */}
          <Grid item container className={formField}>
            <PatternPhoneField
              name="oncologist_phone"
              control={control}
              label="Oncologist Phone Number"
              setValue={setValue}
              setError={setError}
              testId={"oncologist_phone"}
              clearErrors={clearErrors}
            />
          </Grid>

          <Grid item container xs>
            <Typography
              variant="h2"
              className={subtitle}
              align="left"
            >
              Demographic Information
            </Typography>
          </Grid>
          <Grid item container xs>
            <Typography
              variant="subtitle2"
              className={birthdayLabel}
              align="left"
            >
              What is your birthday?
            </Typography>
          </Grid>
          {/* Birthday */}
          <Grid item container className={formField}>
            <PatternDatePicker
              name="birthday"
              label="Date of Birth &#42;"
              control={control}
              defaultValue={birthday}
              testId={"donation-info-birthday"}
            />
          </Grid>

          {/* Race */}
          <Grid item container className={formField}>
            <PatternMultiSelect
              control={control}
              name="selectedRaces"
              label="What is your race? (select all that apply)"
              options={getRaces()}
              fullWidth
              testId={"selected-races"}
              setValue={setValue}
              onSelect={handleSelect}
            />
          </Grid>

          {/* Sex */}
          <Grid item container className={formField}>
            <PatternSelect
              control={control}
              defaultValue=""
              name="sex"
              label="What is your biological sex at birth?"
              testId={"sex"}
              fullWidth
            >
              <MenuItem value="Male">Male</MenuItem>
              <MenuItem value="Female">Female</MenuItem>
              <MenuItem value="Prefer Not to Answer">Prefer Not to Answer</MenuItem>
            </PatternSelect>
          </Grid>

          {consentStudyName === "Cancer Cell Line Project" && <AboveTwentyOne/>}

          <Grid item className={formField}>
            <Box mt={5}>
              <Button
                type="submit"
                color="primary"
                variant="contained"
                data-testid={"submit"}
                disabled={isDonationInfoFormInvalid(isValid, surgeonPhone, oncologistPhone)}
              >
                 Save and Continue
              </Button>
            </Box>
          </Grid>
        </Grid>
      </form>
    </Grid>
  );
};

DonationInfoForm.propTypes = {
  consentStudyName: PropTypes.string,
  birthday: PropTypes.instanceOf(Date),
  onSubmit: PropTypes.func
};

export default DonationInfoForm;