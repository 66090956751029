import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%"
  },
  actions: {
    justifyContent: "flex-end"
  }
}));

export default useStyles;