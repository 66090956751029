import { Button, CardActions, CardContent, Grid } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { PatternTextField } from "shared/components";
import { loginFormEmailSchema } from "shared/utils/formUtils";
import { useAppLocation } from "shared/hooks";
import { useStyles } from "./index";
import { yupResolver } from "@hookform/resolvers/yup";
import PropTypes from "prop-types";

const LoginFormEmail = ({ onSubmit }) => {

  const { actions } = useStyles();
  const { navigateTo } = useAppLocation();

  const handleCancel = () => navigateTo("/");

  const {
    handleSubmit,
    control,
    formState: { isValid }
  } = useForm({
    mode: "all",
    defaultValue: "",
    resolver: yupResolver(loginFormEmailSchema)
  });

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      autoComplete="off"
      noValidate
    >
      <CardContent>
        <Grid item container>
          <Controller
            name="email"
            control={control}
            render={({ field, fieldState: { error } }) =>
              <PatternTextField
                variant="standard"
                color="primary"
                label="Email"
                fullWidth
                inputProps={{
                  minLength: 1,
                  maxLength: 100,
                  type: "email"
                }}
                required
                error={!!error}
                helperText={error ? error.message : ""}
                {...field}
              />
            }
          />
        </Grid>
      </CardContent>

      <CardActions className={actions}>
        <Button
          type="submit"
          size="large"
          color="inherit"
          disabled={!isValid}
        >
            Log In
        </Button>

        <Button
          size="large"
          color="inherit"
          onClick={handleCancel}
        >
            Cancel
        </Button>
      </CardActions>
    </form>
  );
};

LoginFormEmail.propTypes = {
  onSubmit: PropTypes.func
};

export default LoginFormEmail;