import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { Controller } from "react-hook-form";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import FormControl from "@mui/material/FormControl";
import PropTypes from "prop-types";

const PatternDatePicker = (
  {
    name,
    control,
    testId,
    label,
    defaultValue,
    ...props
  }
) => {

  return (
    <>
      <FormControl {...props}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Controller
            name={name}
            control={control}
            defaultValue={defaultValue}
            render={({
              fieldState: { error },
              field
            }) => (
              <DatePicker
                label={label}
                variant="inline"
                format="MM/dd/yyyy"
                placeholder="MM/DD/YYYY"
                error={!!error}
                data-testid={testId}
                slotProps={{
                  textField: {
                    variant: "filled",
                    helperText: error ? error.message : ""
                  }
                }}
                {...field}
              />
            )}
          />
        </LocalizationProvider>
      </FormControl>
    </>

  );
};

PatternDatePicker.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  control: PropTypes.any,
  defaultValue: PropTypes.instanceOf(Date),
  testId: PropTypes.string
};

export default PatternDatePicker;