import { createSelector } from "@reduxjs/toolkit";

const authSelector = state => state.auth;

export const selectAuthStatus = createSelector(
  authSelector,
  auth => auth.status
);

export const selectAuthEmail = createSelector(
  authSelector,
  auth => auth.email
);