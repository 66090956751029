import { Grid, Typography } from "@mui/material";
import PropTypes from "prop-types";

const BluePanelStepsDesktop = ({classes, stepBubbles}) => {
  const { stepImages, avatar, avatarSubtitle } = classes;

  return (
    <>
      <Grid
        className={stepImages}
        item
        container
        justifyContent="space-evenly"
        xs={10}
      >
        {stepBubbles.map(({ src, alt }, index) => {
          return (
            <Grid key={index} item container xs={2} justifyContent="center">
              <img src={src} alt={alt} className={avatar}/>
            </Grid>
          );
        })}
      </Grid>

      <Grid
        item
        container
        justifyContent="space-evenly"
        xs={10}
      >
        {stepBubbles.map(({ text }, index) => {
          return (
            <Grid key={index} item container xs={2} justifyContent="center">
              <Typography
                className={avatarSubtitle}
                variant="h3"
                align="center"
              >
                {text}
              </Typography>
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};

BluePanelStepsDesktop.propTypes = {
  classes: PropTypes.object,
  stepBubbles: PropTypes.array
};

BluePanelStepsDesktop.defaultProps = {
  classes: null,
  stepBubbles: null
};

export default BluePanelStepsDesktop;