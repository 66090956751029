import { Box, Grid, Typography } from "@mui/material";
import { useStyles } from "./index";
import PropTypes from "prop-types";

const YourInfoHelpButton = ({ onOpen }) => {

  const { questionBtn, questionsBtnSub } = useStyles();

  return (
    <Grid item xs={12}>
      <Box mt={3} mb={4} onClick={onOpen} className={questionBtn}>
        <Typography variant="subtitle1" align="center" className={questionsBtnSub}>
          Have questions about the enrollment process?
        </Typography>
        <Typography variant="subtitle1" align="center" className={questionsBtnSub}>
          Click here to learn more.
        </Typography>
      </Box>
    </Grid>
  );
};

YourInfoHelpButton.propTypes = {
  onOpen: PropTypes.func
};

export default YourInfoHelpButton;