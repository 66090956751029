import { Link, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import PropTypes from "prop-types";

const FooterLinksDesktop = ({ classes }) => {

  const { linkWrapper, link } = classes;

  return (
    <Typography className={linkWrapper} align="center">
      <Link className={link} component={RouterLink} to="/">Home</Link>
      <Link className={link} href="https://rarecancer.org/news-updates" target="_blank" rel="noreferrer">Blog</Link>
      <Link className={link} component={RouterLink} to="/about">About</Link>
      <Link className={link} component={RouterLink} to="/faq">FAQ</Link>
      <Link className={link} href="https://rarecancer.org/" target="_blank" rel="noreferrer">RCRF</Link>
      <Link className={link} href="mailto:info@pattern.org" target="_blank">Contact</Link>
      <Link className={link} component={RouterLink} to="/terms">Terms</Link>
      <Link className={link} component={RouterLink} to="/privacy">Privacy</Link>
      <Link className={link} href="https://rarecancer.org/newsletter" target="_blank" rel="noreferrer">
        Mailing List
      </Link>
    </Typography>
  );
};

FooterLinksDesktop.propTypes = {
  classes: PropTypes.object
};

FooterLinksDesktop.defaultTypes = {
  classes: null
};

export default FooterLinksDesktop;
