import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  title: {
    fontSize: "2rem",
    fontWeight: 500
  },
  actions: {
    justifyContent: "flex-end"
  },
  cardContent: {
    padding: 0
  }
}));

export default useStyles;