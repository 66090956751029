import * as PropTypes from "prop-types";
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Toolbar,
  Typography
} from "@mui/material";
import { useEffect, useState } from "react";
import { useStyles } from "./index";
import EmailIcon from "@mui/icons-material/Email";

const RevokeConsentDialog = ({ isOpened, onClose, sponsorName }) => {
  const [open, setOpen] = useState(false);
  const { root, header, content, chip, paragraph } = useStyles();

  const style = {
    display: "inline-block"
  };

  useEffect(() => {
    setOpen(isOpened);
  }, [isOpened]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      className={root}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" className={header}>
        <Toolbar color="primary">
          Revoke Consent
        </Toolbar>
      </DialogTitle>
      <DialogContent className={content} style={{padding: "16px"}}>
        { sponsorName && (
          <Typography paragraph className={paragraph}>
            {`We appreciate your consideration for the ${sponsorName}'s research study and we understand that 
            sometimes patients change their mind. To revoke your consent for this study, please send an email
             to the address below.`}
          </Typography>
        )}
        { !sponsorName && (
          <Typography paragraph className={paragraph}>
            We appreciate your consideration of this research study and we understand that sometimes patients change
            their mind. To revoke your consent for this study, please send an email to the address below.
          </Typography>
        )}
        <Box mb={1} style={style}>
          <Chip
            label={<a href="mailto:requests@pattern.org">requests@pattern.org</a>}
            icon={<EmailIcon />}
            className={chip}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          size="large"
          color="inherit"
          onClick={onClose}
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

RevokeConsentDialog.propTypes = {
  isOpened: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  sponsorName: PropTypes.string
};

export default RevokeConsentDialog;