import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: "3.4rem",
    fontWeight: 400,
    color: theme.palette.pattern.blue
  },
  imgContainer: {
    marginBottom: theme.spacing(2)
  },
  imgMobile: {
    margin: theme.spacing(2),
    width: "100%"
  },
  divider: {
    borderBottom: "0.3vh solid rgb(0, 96, 159)"
  }
}));

export default useStyles;