import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(theme => ({
  root: {
    width: "90%",
    padding: theme.spacing(1),
    marginBottom: theme.spacing(2)
  },
  actions: {
    justifyContent: "center"
  },
  card: {
    "& .MuiCard-root": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between"
    }
  },
  cardContent: {
    padding: 0
  },
  button: {
    color: theme.palette.pattern.yellow
  },
  stretch: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between"
  },
  start: {
    justifyContent: "flex-start"
  }
}));

export default useStyles;