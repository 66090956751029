import { configureStore } from "@reduxjs/toolkit";
import {
  persistReducer,
  persistStore
} from "reduxjs-toolkit-persist";
import { rootSaga } from "redux/rootSaga";
import combinedReducer from "redux/combinedReducer";
import createSagaMiddleware from "redux-saga";
import logger from "redux-logger";
import storageSession from "redux-persist/lib/storage/session";

const sagaMiddleware = createSagaMiddleware();
const middlewares = [sagaMiddleware];

if (process.env.NODE_ENV !== "production") {
  middlewares.push(logger);
}
const rootReducer = (state, action) => {
  if (action.type === "auth/logout" || action.type === "auth/leave") { // check for action type
    state = {};
  }
  return combinedReducer(state, action);
};

const persistConfig = {
  key: "root",
  storage: storageSession
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

let store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ thunk: false, serializableCheck: false, }).concat(middlewares),
  devTools: process.env.NODE_ENV !== "production",
});

let persistor = persistStore(store);

sagaMiddleware.run(rootSaga);

export { store, persistor };