import { ErrorBoundary, FallbackComponent } from "shared/components/ErrorBoundary";
import { Footer, Header } from "components/index";
import {
  Navigate,
  Route,
  Routes,
} from "react-router-dom";
import { PatternCircularProgress, RouteGuard } from "shared/components";
import { Suspense, useEffect } from "react";
import { useAppLocation, useGoogleTagManager, useStaticStuff } from "shared/hooks";
import { useStyles } from "./index";

const GOOGLE_TAG_MANAGER_ID = process.env.REACT_APP_GOOGLE_TAG_MANAGER;

const Root = () => {
  const { root } = useStyles();
  const { isLogin, isRoot, getPages } = useAppLocation();
  const { initGTM } = useGoogleTagManager(GOOGLE_TAG_MANAGER_ID);

  const { getStaticStuff } = useStaticStuff();

  useEffect(() => {
    getStaticStuff();
  }, []);

  initGTM();

  return (
    <div
      className={isRoot || isLogin ? null : root}
      style={ isLogin ? { height: "100vh" } : {} }
    >
      { !isLogin && <Header/> }

      <ErrorBoundary fallback={FallbackComponent}>
        <Suspense fallback={<PatternCircularProgress/>}>
          <RouteGuard>
            <Routes>
              { getPages().map(({ path, pageComponent }, index) => (
                <Route exact path={path} key={index} element={pageComponent} />
              ))}
              <Route
                path="*"
                element={<Navigate to="/" replace />}
              />
            </Routes>
          </RouteGuard>
        </Suspense>
      </ErrorBoundary>

      { !isLogin && <Footer/> }
    </div>
  );
};

export default Root;
