import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiPaper-root": {
      maxWidth: "80%",
      maxHeight: "80%"
    }
  },
  header: {
    backgroundColor: theme.palette.pattern.blue,
    padding: 0,
    fontSize: "2rem",
    fontWeight: 500,
    color: theme.palette.pattern.white
  },
  content: {
    padding: 0,
    marginBottom: 0
  },
  list: {
    overflow: "auto",
    position: "relative",
  },
  paragraphTitle: {
    display: "flex",
    alignItems: "center",
    fontSize: "2rem",
    fontWeight: 500,
    marginBottom: theme.spacing(1),
    color: theme.palette.pattern.blackDialog,
    "& svg": {
      fontSize: "2rem",
      fontWeight: 400
    }
  },
  paragraph: {
    fontSize: "2rem",
    fontWeight: 400,
    marginBottom: theme.spacing(1),
    color: theme.palette.pattern.blackDialog
  },
  subParagraph: {
    fontSize: "1.6rem",
    fontWeight: 400,
    marginBottom: theme.spacing(1),
    color: theme.palette.pattern.blackDialog,
    "& span": {
      fontSize: "1.6rem",
      fontWeight: 700
    }
  },
  chip: {
    marginRight: theme.spacing(2),
    backgroundColor: theme.palette.pattern.green,
    color: theme.palette.pattern.white,
    fontSize: "1.3rem",
    fontWeight: 500,
    paddingLeft: "1.2rem",
    paddingRight: "1.2rem",
    "& svg": {
      color: theme.palette.pattern.white,
      fontSize: "1.3rem",
      fontWeight: 400,
    },
    "& a": {
      textDecoration: "none",
      color: theme.palette.pattern.white,
    }
  },
  footer: {
    borderTop: "1px solid rgba(0, 0, 0, 0.12)"
  }
}));

export default useStyles;