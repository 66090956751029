const { makeStyles } = require("@mui/styles");

const useStyles = makeStyles(theme => (({
  radioGroup: {
    justifyContent: "space-around",
    [theme.breakpoints.down("md")]: {
      justifyContent: "space-between",
    }
  }
})));

export { useStyles };