import * as PropTypes from "prop-types";
import { ConsentHeaderMobile } from "./ConsentHeaderMobile";
import { ConsentStepsDesktop } from "./ConsentStepsDesktop";
import { ConsentText } from "./ConsentText";
import { ContactInformation } from "./ContactInformationDialog";
import { Grid, Grow, useMediaQuery } from "@mui/material";
import { consent } from "./propTypes";
import { useState } from "react";
import { useStyles } from "./index";

const ConsentView = ({
  consent,
  consentStudyName,
  currentConsentStep,
  maxConsentStep,
  onStepClick,
  onStepMobileClick,
  onClickUnderstand,
  onClickUnderstandLastStep
}) => {


  const [ open, setOpen ] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const isMobile = useMediaQuery(theme => theme.breakpoints.down("md"));

  const {
    root,
    imgWrapper,
    img,
    consentForm,
    amendmentInfo,
  } = useStyles();

  if (!consent) return null;

  const { study, sponsor, steps, amendment_info } = consent;

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      className={root}
    >
      {isMobile && (
        <ConsentHeaderMobile
          name={sponsor.name}
          logoUrl={sponsor.logoUrl}
          study={study}
          steps={steps}
          maxConsentStep={maxConsentStep}
          currentConsentStep={currentConsentStep}
          onStepMobileClick={onStepMobileClick}
        />
      )}

      {!isMobile && (
        <Grid
          item
          container
          justifyContent="center"
          xs={4}
        >
          <Grid item xs={2}></Grid>
          <Grid item xs={6}>
            <Grow in={!!sponsor.logoUrl}>
              <img
                className={img}
                src={sponsor.logoUrl}
                alt={`${sponsor.name} logo`}
              />
            </Grow>
          </Grid>
        </Grid>
      )}

      <Grid
        item
        container
        direction={isMobile ? "column" : "row"}
        xs={11}
        className={consentForm}
        spacing={2}
      >

        {!isMobile && <ConsentStepsDesktop
          study={study}
          steps={steps}
          maxConsentStep={maxConsentStep}
          currentConsentStep={currentConsentStep}
          onStepClick={onStepClick}
        />}

        {isMobile && (
          <Grid
            item
            container
            className={imgWrapper}
          >
            <Grow in={!!study.logoUrl}>
              <img
                className={img}
                src={study.logoUrl}
                alt={`${study.name} logo`}
              />
            </Grow>
          </Grid>
        )}

        <ConsentText
          steps={steps}
          consentStudyName={consentStudyName}
          currentConsentStep={currentConsentStep}
          onClickUnderstand={onClickUnderstand}
          onClickUnderstandLastStep={onClickUnderstandLastStep}
          onContactInfoOpen={handleOpen}
        />

        <ContactInformation
          isOpened={open}
          onClose={handleClose}
        />

        <Grid
          item
          container
          className={amendmentInfo}
        >
          <div
            dangerouslySetInnerHTML={{__html: amendment_info}}
          >
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
};

ConsentView.propTypes = {
  consent,
  consentStudyName: PropTypes.string,
  currentConsentStep: PropTypes.number,
  maxConsentStep: PropTypes.number,
  onStepClick: PropTypes.func,
  onStepMobileClick: PropTypes.func,
  onClickUnderstand: PropTypes.func,
  onClickUnderstandLastStep: PropTypes.func
};

export default ConsentView;