import * as PropType from "prop-types";
import { CircularProgress, Grid, Grow, useMediaQuery } from "@mui/material";
import { useStyles } from "./index";

const Partners = ({ foundationPartners }) => {

  const { partnerLogo, partnerLogoSmall } = useStyles();
  const breakpoint = useMediaQuery(theme => theme.breakpoints.down("md"));

  if (foundationPartners && foundationPartners.length === 0) {
    return <CircularProgress style={{marginBottom: "50px"}}/>;
  }

  return (
    <Grid
      item
      container
      xs={12}
      direction={breakpoint ? "column" : "row"}
      justifyContent="space-evenly"
      alignItems="center"
      spacing={2}
    >
      {foundationPartners && foundationPartners.map(({ name, logo_http_url, occupies  }, index) => {
        return (
          <Grow key={index} in={!!logo_http_url}>
            <Grid item xs={12} md={breakpoint ? 12 : occupies}>
              <img src={logo_http_url} className={breakpoint ? partnerLogoSmall : partnerLogo} alt={name}/>
            </Grid>
          </Grow>
        );
      })}
    </Grid>
  );
};

export default Partners;

Partners.propTypes = {
  foundationPartners: PropType.array,
};