import * as PropTypes from "prop-types";
import { clearSession } from "../../utils/sessionStorageUtils";
import { unstable_useBlocker as useBlocker } from "react-router-dom";
import { useEffect } from "react";

// eslint-disable-next-line react/prop-types
const PatternPrompt = ({when, message, onProceed}) => {
  let blocker = useBlocker(when);

  const handleBeforeUnload = (e) => {
    clearSession();
    e.preventDefault();
    e.returnValue = "";
  };

  useEffect(() => {
    if (blocker.state === "blocked") {
      let proceed = window.confirm(message);
      if (proceed) {
        blocker.proceed();
        onProceed();
      } else {
        blocker.reset();
      }
    }
  }, [blocker, message]);

  useEffect(() => {
    if (blocker.state === "blocked" && !when) {
      blocker.reset();
    }

    if (when) {
      window.addEventListener("beforeunload", handleBeforeUnload);
    }

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [blocker, when]);
};


PatternPrompt.propTypes = {
  when: PropTypes.bool,
  message: PropTypes.string,
  onProceed: PropTypes.func,
};
export default PatternPrompt;