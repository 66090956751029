import * as PropType from "prop-types";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  Typography
} from "@mui/material";
import { PatternCircularProgress } from "shared/components";
import { YourInfoErrorDialog } from "./YourInfoErrorDialog";
import {
  YourInfoFormWrapper,
  YourInfoHelp,
  YourInfoNotEligibleDialog,
  YourInfoPersonalInfoForm,
  YourInfoScreeningForm,
  useStyles
} from "./index";
import { i18n } from "shared/constants/i18n";

const YourInfoView = ({
  userError,
  userStatus,
  isLogged,
  isScreeningForm,
  isPersonalInfoForm,
  isNotEligibleDialogOpen,
  email,
  onCloseNonEligibleDialog,
  onSubmitPersonalInfoForm,
  onSubmitScreeningForm,
  onGoToNextStep,
  onNavigateToAccount,
  cancers,
  onCloseErrorDialog,
  clearErrors
}) => {

  const {
    title,
    paragraph,
  } = useStyles();

  if (userStatus === "loading") {
    return  <PatternCircularProgress />;
  }

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
    >
      <Grid
        item
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item>
          <Box mt={3} mb={3}>
            <Typography variant="h1" className={title}>
              Your Information
            </Typography>
          </Box>
        </Grid>

        <YourInfoErrorDialog
          isOpened={userStatus === "failed"}
          email={email}
          onClose={onCloseErrorDialog}
          error={userError}
          clearErrors={clearErrors}
        />

        { isLogged && (
          <Grid item>
            <Card>
              <CardContent>
                <Typography style={{fontSize: "1.6rem"}}>
                  Now that you&apos;re logged in, if you need to change any of your personal information,
                  please access it via the Account page. Otherwise, go on to the next step.
                </Typography>
              </CardContent>
              <CardActions>
                <Button onClick={onNavigateToAccount}>Account</Button>
                <Button onClick={onGoToNextStep}>Next step</Button>
              </CardActions>
            </Card>
          </Grid>
        )}

        { !isLogged && isScreeningForm && (
          <YourInfoFormWrapper className={paragraph} text={i18n.enrollment.eligibilityForm.yourInfoFirstTitle}>
            <YourInfoScreeningForm onSubmit={onSubmitScreeningForm}/>
          </YourInfoFormWrapper>
        )}

        { !isLogged && isPersonalInfoForm && (
          <YourInfoFormWrapper className={paragraph} text={i18n.enrollment.eligibilityForm.yourInfoSecondTitle}>
            <YourInfoPersonalInfoForm onSubmit={onSubmitPersonalInfoForm} email={email} cancers={cancers}/>
          </YourInfoFormWrapper>
        )}

        <YourInfoNotEligibleDialog isOpened={isNotEligibleDialogOpen} onClose={onCloseNonEligibleDialog}/>
        <YourInfoHelp/>
      </Grid>
    </Grid>
  );
};

YourInfoView.propTypes = {
  userError: PropType.object,
  userStatus: PropType.string,
  isLogged: PropType.bool,
  isScreeningForm: PropType.bool,
  isPersonalInfoForm: PropType.bool,
  isNotEligibleDialogOpen: PropType.bool,
  email: PropType.string,
  onSubmitPersonalInfoForm: PropType.func,
  onSubmitScreeningForm: PropType.func,
  onCloseNonEligibleDialog: PropType.func,
  onGoToNextStep: PropType.func,
  onNavigateToAccount: PropType.func,
  cancers: PropType.array,
  onCloseErrorDialog: PropType.func,
  clearErrors: PropType.func
};

export default YourInfoView;