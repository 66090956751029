import { Box, Paper } from "@mui/material";
import { useEnrollmentStepper } from "shared/hooks";
import { useStyles } from "./index";
import AppBar from "@mui/material/AppBar";
import PropTypes from "prop-types";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";

const TabElement = ({ number, text }) => {

  const { tabElementStep, tabContainer } = useStyles();

  return (
    <Box className={tabContainer}>
      <span className={tabElementStep}>{number}</span>
      <Box sx={{ display: { xs: "none", md: "block" } }}>
        {text}
      </Box>
    </Box>
  );
};

TabElement.propTypes = {
  number: PropTypes.number.isRequired,
  text: PropTypes.string.isRequired,
};

function a11yProps(index){
  return {
    id: `enrollment-tab-${index}`,
    "aria-controls": `enrollment-tabpanel-${index}`,
  };
}

const EnrollmentStepper = ({ isEnrollmentPage }) => {

  const { root, tabIndicator } = useStyles();

  const { currentEnrollmentStep, maxEnrollmentStep, updateEnrollmentStep } = useEnrollmentStepper();

  // todo conditional return after hook calls because of new react warning
  if (!isEnrollmentPage) {
    return null;
  }
  const handleChange = (event, newValue) => updateEnrollmentStep({ currentEnrollmentStep: newValue });

  return (
    <Paper elevation={0}>
      <AppBar position="static" className={root}>
        <Tabs
          textColor="inherit"
          value={currentEnrollmentStep}
          onChange={handleChange}
          aria-label="enrollment-tabs"
          variant="fullWidth"
          centered
          TabIndicatorProps={{ className: tabIndicator }}
        >
          <Tab
            label={<TabElement number={1} text={"Your Information"}/>}
            {...a11yProps(0)}
          />
          <Tab
            label={<TabElement number={2} text={"Study Information"}/>}
            disabled={!(maxEnrollmentStep >= 1)}
            {...a11yProps(1)}
          />
          <Tab
            label={<TabElement number={3} text={"Review Consent Form"}/>}
            disabled={!(maxEnrollmentStep >= 2)}
            {...a11yProps(2)}
          />
          <Tab
            label={<TabElement number={4} text={"Donation Information"}/>}
            disabled={!(maxEnrollmentStep >= 3)}
            {...a11yProps(3)}
          />
        </Tabs>
      </AppBar>
    </Paper>
  );
};

EnrollmentStepper.propTypes = {
  isEnrollmentPage: PropTypes.bool.isRequired
};

EnrollmentStepper.defaultTypes = {
  isEnrollmentPage: true
};

export default EnrollmentStepper;
