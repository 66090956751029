import * as PropTypes from "prop-types";
import { Grid, Typography } from "@mui/material";
import { useStyles } from "./index";

const SuccessCardColumn = ({ color, header, children }) => {

  const { root, img, headerClass } = useStyles();

  return (
    <Grid
      item
      container
      xs={11}
      md={4}
      justifyContent="flex-start"
      direction="column"
      className={root}
    >
      <Grid item container justifyContent="center">
        <div className={img} style={{ backgroundColor: color }}></div>
      </Grid>
      <Grid item>
        <Typography
          variant="h3"
          align="center"
          style={{ color: color }}
          className={headerClass}
        >
          {header}
        </Typography>
      </Grid>
      {children}
    </Grid>
  );
};

SuccessCardColumn.propTypes = {
  color: PropTypes.string,
  header: PropTypes.string,
  children: PropTypes.node
};

export default SuccessCardColumn;