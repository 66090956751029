import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid, Grow,
  Paper,
  Typography,
  useMediaQuery
} from "@mui/material";
import { RevokeConsentDialog, useStyles } from "./index";
import { useState } from "react";
import PropTypes from "prop-types";

const CurrentEnrollments = ({ singleCase }) => {

  const isMobile = useMediaQuery(theme => theme.breakpoints.down("md"));

  const { root, button, actions, stretch, start } = useStyles();

  const { protocol, donation_information, consent } = singleCase;

  const [ isOpened, setIsOpened ] = useState(false);

  const handleOpen = () => {
    setIsOpened(true);
  };

  const handleClose = () => {
    setIsOpened(false);
  };

  const handleDownload = url => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  return (
    <Grow in={!!singleCase}>
      <Paper className={root} elevation={6} square>
        <Grid
          container
          alignItems="stretch"
          direction={isMobile ? "column" : "row"}
          spacing={2}
        >
          {/*Study info card*/}
          <Grid item xs={12} md={4}>
            <Card className={stretch}>
              <CardHeader title="Study Information"/>
              <CardContent>
                <Typography paragraph align="center">
                  {protocol?.name}
                </Typography>
              </CardContent>
              <CardActions className={actions}>
                {consent?.download_url && (
                  <Button color="primary" onClick={() => handleDownload(consent.download_url)}>
                    Download Consent
                  </Button>
                )}
                <Button className={button} onClick={handleOpen}>
                  Revoke Consent
                </Button>
              </CardActions>
            </Card>
          </Grid>

          <RevokeConsentDialog sponsorName={protocol?.name} isOpened={isOpened} onClose={handleClose}/>

          {/*Doctors*/}
          <Grid item xs={12} md={4}>
            <Card className={`${stretch} ${start}`}>
              <CardHeader title="Doctors"/>
              <CardContent>
                <Typography paragraph>
                  Surgeon: {donation_information?.surgeon ? donation_information?.surgeon : "Not Provided" }
                </Typography>
                <Typography paragraph>
                  Oncologist: {donation_information?.oncologist ? donation_information?.oncologist : "Not Provided"}
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          {/*Procedure info card*/}
          <Grid item xs={12} md={4}>
            <Card className={stretch}>
              <CardHeader title="Procedure"/>
              <CardContent>
                {
                  donation_information?.surgery_date && (
                    <Typography paragraph>
                          Date: {donation_information?.surgery_date}
                    </Typography>
                  )
                }
                <Typography paragraph>
                  Location: {donation_information?.hospital.name}
                </Typography>
                <Typography paragraph>
                  { donation_information?.hospital.city}, {donation_information?.hospital.state}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Paper>
    </Grow>
  );
};

CurrentEnrollments.propTypes = {
  singleCase: PropTypes.object
};

export default CurrentEnrollments;