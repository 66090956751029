import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  cancers: [],
  status: "idle",
  error: null
};

const cancersSlice = createSlice({
  name: "cancers",
  initialState,
  reducers: {
    getCancers() {},
    pending(state) {
      state.status = "loading";
    },
    success(state, action) {
      state.status = "succeeded";
      state.cancers = action.payload;
    },
    failure(state, action) {
      state.status = "failed";
      state.error = action.payload;
    }
  }
});

export const {
  getCancers,
  pending,
  success,
  failure
} = cancersSlice.actions;
export default cancersSlice.reducer;