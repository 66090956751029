import { GET_STATIC_STUFF } from "shared/constants/endpoints";
import axios from "axios";

export class StaticStuffService {
  static async getStaticStuff() {
    try {
      const res = await axios.get(GET_STATIC_STUFF);
      return res.data;
    } catch (error) {
      throw error;
    }
  }
}