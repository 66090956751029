import * as PropTypes from "prop-types";
import { Box, Grid, Typography } from "@mui/material";

const YourInfoStepFormWrapper = ({ className, text, children }) => {
  return <>
    <Grid
      item
      container
      justifyContent="center"
      alignItems="center"
    >
      <Box mb={3}>
        <Typography variant="h3" align="center" className={className}>
          {text}
        </Typography>
      </Box>
    </Grid>
    {children}
  </>;
};

YourInfoStepFormWrapper.propTypes = {
  text: PropTypes.string,
  className: PropTypes.any,
  children: PropTypes.node
};

export default YourInfoStepFormWrapper;