import { Box, Button, Grid, Typography } from "@mui/material";
import { useStaticStuff } from "../../shared/hooks";
import { useStyles } from "./index";
import Parser from "html-react-parser";

const About = () => {

  const { title, btn, aboutContainer } = useStyles();

  const { about } = useStaticStuff();

  return (
    <Grid
      item
      container
      direction="column"
      alignItems="center"
      justifyContent="space-between"
    >
      <Grid>
        <Typography className={title}>
            About us
        </Typography>
      </Grid>

      <Grid
        item
        container
        alignItems="center"
        justifyContent="center"
      >
        <Grid item xs={11} className={aboutContainer}>
          {Parser(about)}
        </Grid>
      </Grid>

      <Grid item>
        <Box mt={3} mb={3}>
          <Button
            variant="contained"
            color="primary"
            className={btn}
            href="https://rarecancer.org/newsletter"
            target="_blank"
            rel="noreferrer"
            fullWidth
            style={{ textDecoration: "none" }}
          >
            Join our newsletter
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};

export default About;
