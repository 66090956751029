import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(5)
  },
  title: {
    marginTop: "1rem",
    marginBottom: "2rem",
    fontSize: "2.4rem",
    fontWeight: "400",
    color: theme.palette.pattern.blue
  },
  item: {
    minWidth: "100%"
  },
  header: {
    "& span, h3": {
      fontWeight: "bold",
      padding: "2.6rem 0 1.6rem 1.6rem",
      display: "inline-block"
    }
  },
  link: {
    "& a": {
      textDecoration: "none",
      fontSize: "1.6rem",
      fontWeight: 400,
      color: theme.palette.pattern.secondaryBg,
      cursor: "pointer",
      "&:hover": {
        textDecoration: "underline"
      }
    }
  }
}));

export default useStyles;