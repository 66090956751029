import {
  Card,
  CardHeader,
  Grid
} from "@mui/material";
import { ContactInfoForm, ContactInfoPanel } from "./index";
import{ useState } from "react";
import PropTypes from "prop-types";

const ContactInfo = ({ personal, onSubmit }) => {

  const [ isEditing, setIsEditing ] = useState(false);

  const handleIsEditing = () => setIsEditing(!isEditing);

  const handleSubmit = formData => {
    onSubmit(formData);
    setIsEditing(!isEditing);
  };

  return (
    <Grid
      item
      container
      justifyContent="center"
    >
      <Grid item xs={11} md={6}>
        <Card>
          <CardHeader title="Contact Information">
          </CardHeader>
          { !isEditing && <ContactInfoPanel personal={personal} onClick={handleIsEditing}/> }
          { isEditing && <ContactInfoForm personal={personal} onSubmit={handleSubmit} onClick={handleIsEditing}/> }
        </Card>
      </Grid>
    </Grid>
  );
};

ContactInfo.propTypes = {
  personal: PropTypes.object,
  onSubmit: PropTypes.func
};

export default ContactInfo;