import { CircularProgress } from "@mui/material";

export default function PatternCircularProgress() {
  return (
    <div
      style={{
        position: "absolute",
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)"
      }}
    >
      <CircularProgress color="primary" size={80}/>
    </div>
  );
}