import { Controller } from "react-hook-form";
import { FormHelperText } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import PropTypes from "prop-types";
import Select from "@mui/material/Select";

const PatternSelect = ({
  name,
  label,
  control,
  children,
  helperText,
  testId,
  ...props
}) => {
  const labelId = `${name}-label`;

  return (
    <>
      <FormControl variant="standard" {...props} >
        <InputLabel id={labelId}>{label}</InputLabel>
        <Controller
          render={ ({ field }) =>
            <Select
              labelId={labelId}
              label={label}
              SelectDisplayProps={{ "data-testid": `${testId}` }}
              {...field}
            >
              {children}
            </Select>
          }
          name={name}
          control={control}
        />
      </FormControl>
      <FormHelperText style={{ color: "#d08405", fontWeight: 500 }}>{helperText}</FormHelperText>
    </>
  );
};

PatternSelect.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  control: PropTypes.any,
  helperText: PropTypes.string,
  children: PropTypes.node,
  testId: PropTypes.string
};

export default PatternSelect;