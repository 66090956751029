import {
  Box,
  Button,
  Grid
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { PatternRadioQuestion, PatternTextField } from "shared/components";
import { i18n } from "shared/constants/i18n";
import { screeningSchema } from "shared/utils/formUtils";
import { useStyles } from "./YourInfoScreeningForm.styles";
import { useUser } from "shared/hooks";
import { yupResolver } from "@hookform/resolvers/yup";
import PatternPrompt from "shared/components/PatternPrompt/PatternPrompt";
import PropTypes from "prop-types";

const YourInfoScreeningForm = ({ onSubmit }) => {

  const defaultValues = {
    email: "",
    tissueExists: "",
    countryTissue: ""
  };

  const { formWidth } = useStyles();

  const {
    control,
    handleSubmit,
    watch,
    formState: { isValid, isDirty}
  } = useForm({
    defaultValues,
    mode: "all",
    resolver: yupResolver(screeningSchema)
  });

  const { leavePage } = useUser();

  const tissueExists = watch("tissueExists");

  return (
    <Grid item container alignItems="center" justifyContent="center">
      <PatternPrompt
        when={isDirty}
        message={"You are about to leave the enrollment process. Data will be lost."}
        onProceed={leavePage}
      />
      <form
        onSubmit={handleSubmit(onSubmit)}
        autoComplete="off"
        noValidate
        className={formWidth}
      >
        <Grid
          item
          container
          direction="row"
          alignItems="center"
          justifyContent="space-around"
        >
          <Grid item xs={10} md={6}>
            <Controller
              name="email"
              control={control}
              render={({ field , fieldState: { error }}) =>
                <PatternTextField
                  variant="standard"
                  color="primary"
                  label="Email"
                  fullWidth
                  inputProps={{
                    minLength: 1,
                    maxLength: 100,
                    type: "email",
                    "data-testid": "email",
                  }}
                  error={!!error}
                  helperText={error ? error.message : ""}
                  required
                  {...field}
                />
              }
            />
          </Grid>

          <Grid item container>
            <PatternRadioQuestion
              testId={"tissue-exists"}
              top={5}
              control={control}
              field={"tissueExists"}
              title={i18n.enrollment.screeningForm.tissueExists}
            />
          </Grid>

          { tissueExists === "Yes" && (
            <Grid item container>
              <PatternRadioQuestion
                testId={"country-tissue"}
                control={control}
                field={"countryTissue"}
                title={i18n.enrollment.screeningForm.countryTissue}
              />
            </Grid>
          )}

          <Grid item>
            <Box mt={2} mb={1}>
              <Button
                data-testid="save-button"
                type="submit"
                color="primary"
                variant="contained"
                disabled={!isValid}
              >
                Save and Continue
              </Button>
            </Box>
          </Grid>
        </Grid>
      </form>
    </Grid>
  );
};

YourInfoScreeningForm.propTypes = {
  onSubmit: PropTypes.func
};

export default YourInfoScreeningForm;