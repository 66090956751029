import { AboveTwentyOne } from "../../../AboveTwentyOne";
import { Button, Grid } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { PatternDatePicker, PatternTextField } from "shared/components";
import { consentSchema } from "shared/utils/formUtils";
import { useStyles } from "./index";
import { useUser } from "shared/hooks";
import { yupResolver } from "@hookform/resolvers/yup";
import HelpIcon from "@mui/icons-material/Help";
import PatternPrompt from "shared/components/PatternPrompt/PatternPrompt";
import PropTypes from "prop-types";

const ConsentFinalStepForm = ({onSubmit, onOpen, acceptButtonText, consentStudyName}) => {

  const { btnWrapper, consentHelpBtn, fullName } = useStyles();

  const defaultValues = {
    fullName: "",
    birthday: null
  };

  const {
    control,
    handleSubmit,
    formState: { isValid, errors, isDirty }
  } = useForm({
    defaultValues,
    mode: "all",
    resolver: yupResolver(consentSchema)
  });

  const { leavePage } = useUser();

  return (
    <Grid
      item
      container
      xs={12}
      justifyContent="center"
      alignItems="center"
      direction="column"
    >
      {consentStudyName === "Cancer Cell Line Project" && (
        <Grid item>
          <AboveTwentyOne/>
        </Grid>
      )}

      <PatternPrompt
        when={isDirty}
        message={"You are about to leave the enrollment process. Data will be lost."}
        onProceed={leavePage}
      />

      <Grid item>
        <form
          onSubmit={handleSubmit(onSubmit)}
          autoComplete="off"
          noValidate
        >
          <Grid item container xs={12}>
            <Grid item container justifyContent="space-between">
              <Grid item className={fullName}>
                <Controller
                  name="fullName"
                  control={control}
                  render={({ field }) =>
                    <PatternTextField
                      variant="standard"
                      color="primary"
                      label="Full Name"
                      fullWidth
                      error={!!errors.fullName}
                      helperText={errors.fullName ? "This is required" : ""}
                      required
                      data-testid="consent-full-name"
                      {...field}
                    />
                  }
                />
              </Grid>
              <Grid item>
                <PatternDatePicker
                  name="birthday"
                  label="Date of Birth &#42;"
                  control={control}
                  testId={"consent-birthday"}
                />
              </Grid>
            </Grid>

            <Grid
              item
              container
              justifyContent="space-around"
              direction="column"
              alignItems="center"
            >
              <Grid item className={btnWrapper}>
                <Button
                  type="submit"
                  variant="contained"
                  color="secondary"
                  disabled={!isValid}
                >
                  {acceptButtonText}
                </Button>
              </Grid>
              <Grid item className={btnWrapper}>
                <Button
                  className={consentHelpBtn}
                  variant="contained"
                  startIcon={<HelpIcon fontSize="small"/>}
                  onClick={onOpen}
                >
                  I have a question
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Grid>
  );
};

ConsentFinalStepForm.propTypes = {
  consentStudyName: PropTypes.string,
  acceptButtonText: PropTypes.string,
  onSubmit: PropTypes.func,
  onOpen: PropTypes.func
};

export default ConsentFinalStepForm;