import * as PropTypes from "prop-types";
import { Box, Button, Grid, useMediaQuery } from "@mui/material";
import { step } from "../../propTypes";
import { useStyles } from "./index";
import HelpIcon from "@mui/icons-material/Help";

const ConsentActions = ({ step, onClickUnderstand, onOpen }) => {

  const isMobile = useMediaQuery(theme => theme.breakpoints.down("md"));

  const { btnWrapper, consentHelpBtn } = useStyles();

  return (
    <Grid
      item
      container
      direction={isMobile ? "column" : "row"}
      justifyContent="space-around"
      className={btnWrapper}
      alignItems="center"
    >
      <Grid item>
        <Box mb={2}>
          <Button
            className={consentHelpBtn}
            variant="contained"
            startIcon={<HelpIcon fontSize="small"/>}
            onClick={onOpen}
          >
            I have a question
          </Button>
        </Box>
      </Grid>
      <Grid item>
        <Box mb={2}>
          <Button
            variant="contained"
            color="secondary"
            onClick={onClickUnderstand}
          >
            {step.accept_button_text}
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};

ConsentActions.propTypes = {
  step,
  currentConsentStep: PropTypes.number,
  onClickUnderstand: PropTypes.func,
  onOpen: PropTypes.func
};

export default ConsentActions;