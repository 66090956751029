import { UserService } from "services/userService";
import { call, put, select } from "redux-saga-test-plan/matchers";
import {
  failure,
  getUser,
  patchUser,
  pending,
  saveUser,
  success
} from "redux/slices/userSlice";
import { getStudyInfo } from "redux/slices/studyInfoSlice";
import { selectCancers } from "../selectors/cancersSelector";
import { serialize } from "shared/utils/sessionStorageUtils";
import { serializePatchUserData, serializeUserData } from "shared/utils/formUtils";
import { setEnrollmentStep } from "redux/slices/enrollmentSlice";
import { takeLatest } from "redux-saga/effects";

export const userSaga = [
  takeLatest(getUser, handleFetchUser),
  takeLatest(saveUser, handleSaveUser),
  takeLatest(patchUser, handlePatchUser)
];

function* handleFetchUser() {
  try {
    yield put(pending());
    const response = yield call(UserService.getUser);
    serialize("personalData", response.data);
    yield put(success(response));
  } catch (error) {
    yield put(failure(error));
  }
}

function* handleSaveUser({ payload }) {
  try {
    yield put(pending());
    const cancers = yield select(selectCancers);
    const userData = serializeUserData(payload, cancers);
    const response = yield call(UserService.saveUser, userData);
    yield put(success(response));
    const personalInfo = response.data.personal;
    serialize("personalInfo", personalInfo);
    serialize("personalData", response.data);
    yield put(getStudyInfo({ cancer_id: personalInfo.cancer_diagnosis.id }));
    yield put(setEnrollmentStep({ currentEnrollmentStep: 1, maxEnrollmentStep: 1 }));
    // serialize("store", store.getState()); todo - remove
  } catch (error) {
    yield put(failure(error));
  }
}

function* handlePatchUser({ payload }) {
  try {
    yield put(pending());
    const patchUserData = serializePatchUserData(payload);
    const response = yield call (UserService.patchUser, patchUserData);
    serialize("personalData", response.data);
    yield put(success(response));
  } catch (error) {
    yield put(failure(error));
  }
}