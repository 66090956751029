import {
  Button,
  CardActions,
  CardContent,
  Typography
} from "@mui/material";
import { useStyles } from "./index";
import PropTypes from "prop-types";

const ContactInfoPanel = ({ personal, onClick }) => {

  const { actions } = useStyles();

  const { first_name, last_name, phone_number, email } = personal;

  return (
    <>
      <CardContent>
        <Typography paragraph>
          { first_name } { last_name }
        </Typography>
        <Typography paragraph>
          { phone_number }
        </Typography>
        <Typography paragraph>
          { email }
        </Typography>
      </CardContent>
      <CardActions className={actions}>
        <Button
          color="inherit"
          size="large"
          onClick={onClick}
        >
          Edit
        </Button>
      </CardActions>
    </>
  );
};

ContactInfoPanel.propTypes = {
  personal: PropTypes.object,
  onClick: PropTypes.func
};

export default ContactInfoPanel;