import { BluePanelSteps, BluePanelVideo } from "./index";
import { Box, Grid, Paper, Typography } from "@mui/material";
import { useStyles } from "./index";

const BluePanel = () => {

  const { root, title, subtitle } = useStyles();

  return (
    <Paper className={root} square>
      <Grid
        item
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={10}>
          <Box mt={10} mb={5}>
            <Typography variant="h1" className={title} align="center">
              Pattern.org enables cancer patients to direct their tumor
              tissue to researchers working on building cancer models.
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={8}>
          <Box mb={5}>
            <Typography variant="h2" className={subtitle} align="center">
              There are just <span>four</span> steps to participate:
            </Typography>
          </Box>
        </Grid>
        <BluePanelSteps/>
        <BluePanelVideo/>
      </Grid>
    </Paper>
  );
};

export default BluePanel;