import {Toolbar} from "@mui/material";
import { useStyles } from "./index";
import PropTypes from "prop-types";

const PageWrapper = ({ children }) => {

  const { content } = useStyles();

  return (
    <div className={content}>
      <Toolbar/>
      <Toolbar/>
      {children}
    </div>
  );
};

PageWrapper.propTypes = {
  children: PropTypes.node
};

export default PageWrapper;