import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  foundationPartners: [],
  status: "idle",
  error: null
};

const foundationPartnersSlice = createSlice({
  name: "foundationPartners",
  initialState,
  reducers: {
    getFoundationPartners() {},
    pending(state) {
      state.status = "loading";
    },
    success(state, action) {
      state.status = "succeeded";
      state.foundationPartners = action.payload;
    },
    failure(state, action) {
      state.status = "failed";
      state.error = action.payload;
    }
  }
});

export const {
  getFoundationPartners,
  pending,
  success,
  failure
} = foundationPartnersSlice.actions;
export default foundationPartnersSlice.reducer;