import { Grid, Typography } from "@mui/material";
import PropTypes from "prop-types";

const BluePanelStepsMobile = ({classes, stepBubbles}) => {

  const { stepImages, avatar, avatarSubtitle } = classes;

  return (
    <>
      {stepBubbles.map(({ src, alt, text }, index) => {
        return (
          <Grid
            key={index}
            className={stepImages}
            item
            container
            justifyContent="flex-start"
            alignItems="center"
            xs={10}
          >
            <Grid item container xs={4} justifyContent="center">
              <img src={src} alt={alt} className={avatar}/>
            </Grid>
            <Grid item container xs justifyContent="flex-start">
              <Typography
                className={avatarSubtitle}
                variant="h3"
                align="center"
              >
                {text}
              </Typography>
            </Grid>
          </Grid>
        );
      })}
    </>
  );
};

BluePanelStepsMobile.propTypes = {
  classes: PropTypes.object,
  stepBubbles: PropTypes.array
};

BluePanelStepsMobile.defaultProps = {
  classes: null,
  stepBubbles: null
};

export default BluePanelStepsMobile;