import * as PropTypes from "prop-types";
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Toolbar,
  Typography
} from "@mui/material";
import { useConsent } from "shared/hooks";
import { useEffect, useState } from "react";
import { useStyles } from "./index";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";

const ContactInformation = ({
  isOpened,
  onClose,
}) => {
  const [open, setOpen] = useState(false);
  const { chip, root, header, content, greenChip } = useStyles();

  const { consentContactInfo } = useConsent();

  const style = {
    display: "inline-block",
    padding: "8px"
  };

  useEffect(() => {
    setOpen(isOpened);
  }, [isOpened]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      className={root}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" className={header}>
        <Toolbar color="primary">
          Contact Information
        </Toolbar>
      </DialogTitle>
      <DialogContent className={content} style={{padding: "16px"}}>
        <Typography>
          If you have questions about the study, please contact the researcher or study staff listed below:
        </Typography>
        {consentContactInfo && (
          <>
            <Box mb={1} style={style}>
              <Chip
                label={
                  <a
                    data-testid="chip-phone"
                    href={`tel:${consentContactInfo.phone}`}
                  >
                    {consentContactInfo.phone}
                  </a>
                }
                icon={<PhoneIcon color="white" />}
                className={`${chip} ${greenChip}`}
              />
            </Box>
            <Box mb={1} style={style}>
              <Chip
                label={
                  <a
                    data-testid="chip-email"
                    href={`mailto:${consentContactInfo.email}`}
                  >
                    {consentContactInfo.email}
                  </a>
                }
                icon={<EmailIcon />}
                className={chip}
              />
            </Box>
            <Divider/>
            {consentContactInfo.personnel.map((person, index) => (
              <Box key={index} mb={2}>
                <Typography style={{paddingTop: "16px"}}>
                  {person.name}
                </Typography>
                <Typography style={{fontSize: "14px"}}>
                  {person.title}
                </Typography>
              </Box>
            ))}
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          color="inherit"
          size="large"
          onClick={onClose}
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ContactInformation.propTypes = {
  isOpened: PropTypes.bool,
  onClose: PropTypes.func
};

export default ContactInformation;