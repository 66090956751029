const { makeStyles } = require("@mui/styles");

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiPaper-root": {
      maxWidth: "80%",
    }
  },
  title: {
    fontSize: "2rem",
    fontWeight: 500,
    color: theme.palette.pattern.blackDialog
  },
  paragraph: {
    fontSize: "1.6rem",
    fontWeight: 400,
    marginBottom: theme.spacing(2),
    color: theme.palette.pattern.blackDialog
  }
}));

export default useStyles;