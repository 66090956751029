import AuthGuard from "./AuthGuard";
import PropTypes from "prop-types";

const RouteGuard = ({ children }) => {
  return <AuthGuard>{children}</AuthGuard>;
};

RouteGuard.propTypes = {
  children: PropTypes.node.isRequired
};


export default RouteGuard;