const { makeStyles } = require("@mui/styles");

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
    padding: theme.spacing(2),
    paddingBottom: theme.spacing(5)
  },
  link: {
    fontSize: "1.6rem",
    fontWeight: 500,
    color: theme.palette.pattern.green,
    cursor: "pointer",
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline"
    }
  },
  iconParagraph: {
    "& svg": {
      color: theme.palette.pattern.blackDialog,
      fontSize: "1.4rem",
      fontWeight: 400
    }
  }
}));

export default useStyles;