import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.pattern.blue,
    color: theme.palette.pattern.white,
    fontSize: "1.4rem",
    fontWeight: 700,
    "& .MuiTouchRipple-root": {
      color: theme.palette.pattern.green
    },
    "& button": {
      textTransform: "none",
      opacity: 0.6,
      "&:disabled": {
        color: theme.palette.pattern.blackDisabled,
      },
      "&.Mui-selected": {
        color: theme.palette.pattern.white,
        opacity: 1,
      },
      "& div": {
        fontWeight: 700
      }
    },
  },
  tabContainer: {
    display: "inline-flex",
    alignItems: "center",
    textAlign: "center",
    justifyContent: "space-between"
  },
  tabIndicator: {
    backgroundColor: theme.palette.pattern.green
  },
  tabElementStep: {
    width: "1.75em",
    height: "1.75em",
    backgroundColor: theme.palette.pattern.white,
    color: "#00aef0",
    borderRadius: "50%",
    display: "inline-flex",
    marginRight: theme.spacing(3),
    alignItems: "center",
    textAlign: "center",
    justifyContent: "center"
  }
}));

export default useStyles;