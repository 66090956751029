import { createSelector } from "@reduxjs/toolkit";

const consentSelector = state => state.consent;

export const selectConsent = createSelector(
  consentSelector,
  consent => consent?.consent
);

export const selectConsentStudyName = createSelector(
  selectConsent,
  consent => consent?.study.name
);

export const selectStudyId = createSelector(
  selectConsent,
  consent => consent?.id
);

export const selectConsentStatus = createSelector(
  consentSelector,
  consent => consent.status
);

export const selectCurrentConsentStep = createSelector(
  consentSelector,
  consent => consent.currentConsentStep
);

export const selectMaxConsentStep = createSelector(
  consentSelector,
  consent => consent.maxConsentStep
);

export const selectConsentFormBirthday = createSelector(
  consentSelector,
  consent => consent.consentFormBirthday
);

export const selectConsentContactInfo = createSelector(
  consentSelector,
  consent => consent.consent?.contact_info
);