//todo fill up the texts
export const i18n = {
  enrollment: {
    screeningForm: {
      tissueExists: "Will you have a biopsy, surgery, or a fluid drain in the next 6 months?",
      countryTissue: "Will your procedure take place in the contiguous United States or Canada?"
    },
    eligibilityForm: {
      yourInfoFirstTitle: "This is the first step in the donation process. Answer these questions to determine your " +
        "eligibility in the program.",
      yourInfoSecondTitle: "Please enter your contact information so we can contact you. We'll use your diagnosis" +
        " to match you with the appropriate research studies."
    },
  },

};