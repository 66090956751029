import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  root: {
    textAlign: "center"
  },
  paragraph: {
    fontSize: "1.2rem"
  },
  bold: {
    fontWeight: "bold"
  }
});

export default useStyles;