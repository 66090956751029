import * as PropTypes from "prop-types";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Grid, Grow
} from "@mui/material";
import { useStyles } from "./index";

const StudyInfoCard = ({ studyInfo, onClick }) => {

  const { card, cardContent } = useStyles();

  return (
    <>
      { studyInfo.map(({ id, sponsor, study }, index) => (
        <Grid
          key={index}
          item
          container
          xs={10}
          alignItems="flex-start"
        >
          <Card elevation={2} className={card}>
            <Grid item container xs={12}>
              <Grid item xs={12}>
                <CardHeader
                  title={study.name}
                  subheader={sponsor.name}
                />
              </Grid>
              <Grid
                item
                container
                xs={12}
                justifyContent="space-around"
                alignItems="center"
              >
                <Grid item xs={3}>
                  <Grow in={!!study.logoUrl}>
                    <CardMedia
                      component="img"
                      image={study.logoUrl}
                      alt="Study logo"
                    />
                  </Grow>
                </Grid>
                <Grid item xs={3}>
                  <Grow in={!!sponsor.logoUrl}>
                    <CardMedia
                      component="img"
                      image={sponsor.logoUrl}
                      alt="Sponsor logo"
                    />
                  </Grow>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <CardContent>
                <div
                  dangerouslySetInnerHTML={{__html: study.description}}
                  className={cardContent}
                >
                </div>
              </CardContent>
            </Grid>
            <Grid item container justifyContent="center">
              <CardActions>
                <Button
                  data-testid={`study-button-${index}`}
                  color="primary"
                  variant="contained"
                  onClick={() => onClick(id)}
                >
                  Begin consent
                </Button>
              </CardActions>
            </Grid>
          </Card>
        </Grid>
      ))}
    </>
  );

};

StudyInfoCard.propTypes = {
  studyInfo: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    cancer_name: PropTypes.string,
    study: PropTypes.object,
    sponsor: PropTypes.object
  })),
  onClick: PropTypes.func
};

export default StudyInfoCard;