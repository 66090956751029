import { Card, Grid, Link, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { useStyles } from "./index";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

const NotListed = () => {

  const { root, link, iconParagraph } = useStyles();

  return (
    <Card
      data-testid="not-listed"
      className={root}
      elevation={2}
    >
      <Grid item container spacing={2}>
        <Grid item>
          <Typography>
            Thank you for your interest in contributing to research via Pattern.org. Unfortunately,
            if your cancer type isn&apos;t listed, we don&apos;t support it yet.
            <Link
              className={link}
              href="https://eepurl.com/cq3gz1"
              target="_blank" rel="noreferrer"
            > Sign up for our newsletter </Link>
            so we can alert you if it becomes available in the future.
            In the meantime, there are a number of ways you can remain supportive and stay involved:
          </Typography>
        </Grid>
        <Grid item>
          <Typography className={iconParagraph}>
            <ChevronRightIcon/>
            Send us an email at <Link className={link} href="mailto:info@pattern.org">info@pattern.org </Link>
            to tell us about your specific treatment plan, timeline,
            or indication. There may be ways we can help accommodate your enrollment in a study on Pattern.org.
          </Typography>
        </Grid>
        <Grid item>
          <Typography className={iconParagraph}>
            <ChevronRightIcon/>
            You might not be able to donate right now, but you could play a major role in finding someone who can.
            <Link
              className={link}
              href="https://www.facebook.com/RareCancerRF/"
              target="_blank"
              rel="noreferrer"
            > Like us on Facebook
            </Link>,
            then share our posts with your patient communities to help spread the word.
          </Typography>
        </Grid>
        <Grid item>
          <Typography className={iconParagraph}>
            <ChevronRightIcon/>
            Learn more <Link className={link} component={RouterLink} to="/about">about Pattern.org</Link> and the
            <Link
              className={link}
              href="https://rarecancer.org/"
              target="_blank"
              rel="noreferrer"
            > Rare Cancer Research Foundation</Link>.
            Our <Link className={link} component={RouterLink} to="/faq">
            frequently asked questions</Link> page is also an invaluable resource.
            Not finding what you&apos;re looking for?
            <Link className={link} href="mailto:info@pattern.org"> Email Us</Link>.
          </Typography>
        </Grid>
      </Grid>
    </Card>
  );
};

export default NotListed;