import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  withError: {
    "& label.Mui-focused": {
      color: "#d08405",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#d08405",
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "#d08405",
      },
    },
  }
}));

export default useStyles;