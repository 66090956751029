import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  staticStuff: {
    about: "",
    faqs: [],
    foundationPartners: [],
    privacy: "",
    terms: ""
  },
  status: "idle",
  error: null
};

const staticStuffSlice = createSlice({
  name: "staticStuff",
  initialState,
  reducers: {
    fetchStaticStuff() {},
    pending(state) {
      state.status = "loading";
    },
    success(state, action) {
      state.status = "succeeded";
      state.staticStuff = action.payload;
    },
    failure(state, action) {
      state.status = "failed";
      state.error = action.payload;
    }
  }
});

export const {
  fetchStaticStuff,
  pending,
  success,
  failure
} = staticStuffSlice.actions;
export default staticStuffSlice.reducer;