import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: "3.4rem",
    fontWeight: 400,
    color: theme.palette.pattern.blue
  },
  subTitle: {
    fontSize: "2.4rem",
    fontWeight: 400,
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    color: theme.palette.pattern.gray
  },
  paragraph: {
    fontSize: "2rem",
    fontWeight: 500
  }
}));

export default useStyles;