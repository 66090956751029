import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  successCard: {
    padding: theme.spacing(2)
  },
  blue: theme.palette.pattern.blue,
  green: theme.palette.pattern.green,
  yellow: theme.palette.pattern.yellow,
  link: {
    color: theme.palette.pattern.green,
    fontSize: "1.6rem",
    fontWeight: 500,
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline"
    }
  },
  linkHover: {
    cursor: "pointer"
  }
}));

export default useStyles;