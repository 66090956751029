import { PatternCircularProgress } from "shared/components";
import { StudyInfoView } from "./index";
import { filterStudies, getCancerNameFromSession } from "shared/utils/sessionStorageUtils";
import { useConsent, useEnrollment } from "shared/hooks";

const StudyInfoContainer = () => {

  const { fetchConsent } = useConsent();
  const { studyInfoStatus, studyInfo } = useEnrollment();

  if (studyInfoStatus !== "succeeded") return <PatternCircularProgress />;

  const handleClick = id => fetchConsent(id);

  const cancerName = getCancerNameFromSession();

  return (
    <StudyInfoView
      cancerName={cancerName}
      filteredStudies={filterStudies(studyInfo)}
      onClick={handleClick}
    />
  );
};

export default StudyInfoContainer;