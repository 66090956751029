import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(2),
      padding: theme.spacing(1)
    }
  },
  img: {
    padding: theme.spacing(1),
    height: "72px",
    width: "72px",
    borderRadius: "50%"
  },
  headerClass: {
    fontSize: "20px",
    fontWeight: 500
  }
}));

export default useStyles;