import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%"
  },
  formWidth: {
    width: "100%"
  },
  formField: {
    marginBottom: theme.spacing(3)
  },
  actions: {
    justifyContent: "flex-end"
  }
}));

export default useStyles;