import { Card, CardHeader, Grid, LinearProgress, useMediaQuery } from "@mui/material";
import { LoginFormEmail } from "./LoginFormEmail";
import { LoginFormPasscode } from "./LoginFormPasscode";
import { useStyles } from "./index";
import PatternImg from "assets/images/howPatternWorks/patternTess2.png";
import PatternLogo from "assets/images/logo-pattern.svg";
import PropTypes from "prop-types";

const LoginView = ({ onSubmitEmail, onSubmitPasscode, status }) => {
  const isMobile = useMediaQuery(theme => theme.breakpoints.down("md"));

  const {
    root,
    title,
    imgContainer,
    img,
    imgLogo,
    imgRight,
    loginForm
  } = useStyles();

  const isLogging = status && (status === "loading" || status === "failed");
  const isLoggingPasscode = status === "succeeded";

  return (
    <Grid
      className={root}
      container
      justifyContent="center"
      alignItems="center"
      direction={isMobile ? "column" : "row"}
    >
      {isMobile && (
        <>
          <Grid item>
            <img className={imgLogo} src={PatternLogo} alt="pattern-logo"/>
          </Grid>
        </>
      )}
      {!isMobile && (
        <Grid
          item
          md={4}
          className={imgContainer}
        >
          <img
            className={img}
            style={{transform: "rotate(180deg)"}}
            src={PatternImg} alt="howPatternWorks"
          />
        </Grid>
      )}

      <Grid
        item
        xs={10}
        md={4}
        className={isMobile ? loginForm : "" }
      >
        <Card>
          <CardHeader className={title} title="Pattern.org"/>
          { isLogging && <LinearProgress/> }
          { !isLoggingPasscode && <LoginFormEmail onSubmit={onSubmitEmail} />}
          { isLoggingPasscode && <LoginFormPasscode onSubmit={onSubmitPasscode} /> }
        </Card>
      </Grid>

      {!isMobile && (
        <Grid
          item
          md={4}
          className={imgContainer}
        >
          <img className={`${img} ${imgRight}`} src={PatternImg} alt="howPatternWorks"/>
        </Grid>
      )}

    </Grid>
  );
};

LoginView.propTypes = {
  onSubmitEmail: PropTypes.func,
  onSubmitPasscode: PropTypes.func,
  status: PropTypes.string
};

export default LoginView;