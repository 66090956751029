import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  img: {
    margin: theme.spacing(2),
    width: "70%"
  },
  consentStep: {
    fontSize: "1.4rem",
    fontWeight: 700,
    cursor: "pointer",
    padding: theme.spacing(1),
    margin: theme.spacing(1),
  },
  consentStepDisabled: {
    fontSize: "1.4rem",
    fontWeight: 700,
    padding: theme.spacing(1),
    margin: theme.spacing(1),
    color: theme.palette.pattern.disabledStep,
    cursor: "not-allowed",
  }
}));

export default useStyles;