import { useIdleTimer } from "react-idle-timer";
import { useUser } from "./useUser";
import TagManager from "react-gtm-module";

export const useGoogleTagManager = (gtmId) => {

  const timeout = 20 * 60 * 1000;
  const { logoutUser } = useUser();
  const initGTM = () => {
    const tagManagerArgs = {
      gtmId: gtmId
    };

    TagManager.initialize(tagManagerArgs);
  };

  useIdleTimer({
    timeout,
    onIdle: () => logoutUser(),
    crossTab: {
      emitOnAllTabs: true
    }
  });

  return {
    initGTM
  };
};