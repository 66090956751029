const { makeStyles } = require("@mui/styles");

const useStyles = makeStyles((theme) => ({
  questionBtn: {
    cursor: "pointer"
  },
  questionsBtnSub: {
    fontSize: "1.6rem",
    color: theme.palette.pattern.gray
  },
}));

export default useStyles;