import { Box, Button, CardMedia, Grid, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { useStyles } from "./index";
import { useUser } from "../../../../shared/hooks";

const BluePanelVideo = () => {

  const { title, videoWrapper, aspectRatio16x9, button } = useStyles();

  const { sessionPersonalInfo} = useUser();

  return (
    <Grid
      item
      container
      justifyContent="center"
      direction="column"
    >
      <Grid item>
        <Box mt={5} mb={5}>
          <Typography variant="subtitle1" align="center" className={title}>
            Watch the enrollment process:
          </Typography>
        </Box>
      </Grid>
      <Grid
        item
        container
        md={7}
        xs={10}
        lg={6}
        justifyContent="center"
      >
        <Grid item xs={8}>
          <Box className={videoWrapper} mb={5}>
            <CardMedia
              component="iframe"
              src="https://www.youtube.com/embed/UA04-R-D7_Q"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              className={aspectRatio16x9}
            />
          </Box>
        </Grid>
        <Grid
          item
          container
          xs={8}
          justifyContent="center"
        >
          <Button
            variant="contained"
            color="secondary"
            size="large"
            className={button}
            fullWidth
            component={RouterLink}
            to="/enrollment"
          >
            { sessionPersonalInfo ? "Continue Enrollment" : "Enroll now" }
          </Button>
        </Grid>
      </Grid>

    </Grid>
  );
};

export default BluePanelVideo;