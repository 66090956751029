import * as PropTypes from "prop-types";
import {
  Box,
  Button,
  Grid,
  Typography
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { StudyInfoCard, useStyles } from "./index";

const StudyInfoView = ({
  cancerName,
  filteredStudies,
  onClick
}) => {

  const { title, paragraph } = useStyles();

  return (
    <Grid
      item
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      xs={12}
      data-testid={"study-info"}
    >
      <Grid
        item
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        xs={10}
        md={12}
      >
        <Grid
          item
          xs={10}
          md={12}
        >
          <Box mt={3} mb={3}>
            <Typography variant="h1" className={title} align="center">
              Study Information for {cancerName}
            </Typography>
          </Box>
        </Grid>


        <Grid item container xs={12} md={8} justifyContent="center">
          <Box mb={3}>
            { filteredStudies.length > 0 ? (
              <Typography variant="h1" className={paragraph} align="center" data-testid={"heading1"} mr={20} ml={20}>
                  Based on the information you provided, Pattern.org has matched you with the following project.
                  Please learn more about the scientific team and their goals. When you&apos;re ready to continue,
                  click the button at the bottom of the text.
              </Typography>
            ) : (
              <Typography variant="h1" className={paragraph} align="center" data-testid={"heading2"}>
                  You have participated in all of the available studies for your cancer diagnosis type. Visit your
                  account to see your completed consents.
              </Typography>
            )}
          </Box>
        </Grid>

        { filteredStudies.length === 0 && (
          <Box mt={4} mb={2}>
            <Button
              data-testid={"visit-account"}
              variant="contained"
              color="primary"
              component={RouterLink}
              to="/dashboard"
            >
                Visit account
            </Button>
          </Box>
        )}

        { filteredStudies.length > 0 && (
          <Grid
            xs={12}
            item
            container
            justifyContent="center"
            alignContent="space-between"
          >
            <StudyInfoCard studyInfo={filteredStudies} onClick={onClick} />
          </Grid>
        )}

      </Grid>
    </Grid>
  );
};

StudyInfoView.propTypes = {
  cancerName: PropTypes.string,
  filteredStudies: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    cancer_name: PropTypes.string,
    study: PropTypes.object,
    sponsor: PropTypes.object
  })),
  onClick: PropTypes.func
};

export default StudyInfoView;