const { makeStyles } = require("@mui/styles");

const useStyles = makeStyles(theme => ({
  formWidth: {
    width: "60%"
  },
  formField: {
    marginBottom: theme.spacing(3)
  },
  subtitle: {
    color: theme.palette.pattern.gray,
    fontWeight: 400,
    fontSize: "2.4rem",
    margin: "16px 0"
  },
  birthdayLabel: {
    color: theme.palette.pattern.gray,
    fontWeight: 400,
    fontSize: "1.6rem",
    margin: "16px 0"
  }
}));

export { useStyles };