import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  title: {
    marginTop: "1rem",
    marginBottom: "2rem",
    fontSize: "2.4rem",
    fontWeight: "400",
    color: theme.palette.pattern.blue
  },
  btn: {
    borderRadius: 0,
  },
  aboutContainer: {
    "& a": {
      fontSize: "1.6rem",
      fontWeight: 500,
      color: theme.palette.pattern.green,
      cursor: "pointer",
      textDecoration: "none",
      "&:hover": {
        textDecoration: "underline"
      }
    },
  }

}));

export default useStyles;