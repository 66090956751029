import { IconButton, Link } from "@mui/material";
import { useStyles } from "./index";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PropTypes from "prop-types";

const ArrowButton = ({ to, position, color }) => {

  const { arrow, arrowIcon} = useStyles();

  const handleClick = () => {
    document.getElementById(to).scrollIntoView(true);
  };

  return (
    <IconButton
      component={Link}
      size="medium"
      className={arrow}
      style={{ position, color }}
      onClick={handleClick}
    >
      <ExpandMoreIcon fontSize="large" className={arrowIcon}/>
    </IconButton>
  );
};

ArrowButton.propTypes = {
  to: PropTypes.string,
  position: PropTypes.string,
  color: PropTypes.string
};

export default ArrowButton;