import { createSelector } from "@reduxjs/toolkit";

const staticStuffSelector = state => state.staticStuff;

export const selectStaticStuff = createSelector(
  staticStuffSelector,
  staticStuff => staticStuff?.staticStuff
);

export const selectTerms = createSelector(
  selectStaticStuff,
  staticStuff => staticStuff?.terms
);

export const selectFAQs = createSelector(
  selectStaticStuff,
  staticStuff => staticStuff?.faqs
);

export const selectPrivacy = createSelector(
  selectStaticStuff,
  staticStuff => staticStuff?.privacy
);

export const selectAbout = createSelector(
  selectStaticStuff,
  staticStuff => staticStuff?.about
);

export const selectFoundationPartners = createSelector(
  selectStaticStuff,
  staticStuff => staticStuff?.foundation_partners
);