import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  linkWrapper: {
    fontWeight: 700,
    "& > *": {
      marginRight: theme.spacing(2),
    },
  },
  link: {
    color: theme.palette.pattern.green,
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline"
    }
  },
}));

export default useStyles;