import { BluePanel } from "./BluePanel";
import { CtaFooter } from "./CtaFooter";
import { Jumbotron } from "./Jumbotron";
import { PartnersSection } from "./PartnersPanel";
import { WhitePanel } from "./WhitePanel";
import { useEffect } from "react";

export const LandingHome = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Jumbotron/>
      <WhitePanel/>
      <BluePanel/>
      <PartnersSection/>
      <CtaFooter/>
    </>
  );

};