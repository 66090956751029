import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  user: null,
  status: "idle",
  error: null
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser(state, action) {
      state.user = action.payload;
    },
    getUser() {},
    saveUser() {},
    patchUser() {},
    resetUserErrors(state) {
      state.error = null;
      state.status = "idle";
    },
    pending(state) {
      state.status = "loading";
    },
    success(state, action) {
      state.status = "succeeded";
      state.user = action.payload;
    },
    failure(state, action) {
      state.status = "failed";
      state.error = action.payload;
    }
  }
});

export const {
  setUser,
  getUser,
  saveUser,
  patchUser,
  pending,
  success,
  failure,
  resetUserErrors
} = userSlice.actions;
export default userSlice.reducer;