import * as PropTypes from "prop-types";
import { Grid, MenuItem, Paper, Select } from "@mui/material";
import { steps } from "../propTypes";
import { useStyles } from "./index";

const ConsentStepsMobile = ({
  steps,
  maxConsentStep,
  currentConsentStep,
  onStepChange,
}) => {

  const {
    root,
    selectWrapper,
    consentStep,
    consentStepDisabled,
  } = useStyles();

  return (
    <Grid
      item
      container
      xs={11}
      justifyContent="center"
      className={root}
    >
      <Paper
        square className={selectWrapper}>
        <Select
          value={steps[currentConsentStep].sequence}
          onChange={event => onStepChange(event.target.value)}
          disableUnderline
          fullWidth
          disablePadding
          variant="standard"
        >
          {steps.map((step, index) => {
            if (maxConsentStep < index) {
              return (
                <MenuItem
                  key={index}
                  className={consentStepDisabled}
                  disabled
                >
                  {step.sequence}. {step.section_title}
                </MenuItem>
              );
            } else if (maxConsentStep >= index) {
              return (
                <MenuItem
                  key={index}
                  className={consentStep}
                  style={index === currentConsentStep ? { color: "#00aef0"} : {}}
                  value={step.sequence}
                >
                  {step.sequence}. {step.section_title}
                </MenuItem>
              );
            }
          }
          )}
        </Select>
      </Paper>

    </Grid>
  );
};

ConsentStepsMobile.propTypes = {
  steps,
  maxConsentStep: PropTypes.number,
  currentConsentStep: PropTypes.number,
  onStepChange: PropTypes.func,
};

export default ConsentStepsMobile;