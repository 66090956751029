import {
  Box,
  Button,
  Grid,
  MenuItem,
  Tooltip
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { NotListed } from "./NotListed";
import { PatternPhoneField, PatternTextField } from "shared/components";
import { PatternSelect } from "shared/components/PatternSelect";
import { americanStates, canadianProvinces } from "shared/constants/arrays";
import {
  defaultPersonalInfoValues,
  isEnrollmentPersonalInfoFormInvalid,
  personalInfoSchema
} from "shared/utils/formUtils";
import { useEffect } from "react";
import { useStyles } from "./index";
import { useUser } from "shared/hooks";
import { yupResolver } from "@hookform/resolvers/yup";
import InfoIcon from "@mui/icons-material/Info";
import PatternPrompt from "shared/components/PatternPrompt/PatternPrompt";
import PropTypes from "prop-types";

const YourInfoPersonalInfoForm = ({ onSubmit, email, cancers }) => {

  const {
    formField,
    tooltip
  } = useStyles();

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    setError,
    clearErrors,
    formState: { isValid, errors, isDirty }
  } = useForm({
    defaultValues: defaultPersonalInfoValues,
    mode: "all",
    resolver: yupResolver(personalInfoSchema)
  });

  const countrySelected = watch("country");
  const occurrenceType = watch("occurrence_type");
  const cancerDiagnosis = watch("cancer_diagnosis");
  const confirmEmail = watch("email2");
  const phoneNumber = watch("phone_number");

  useEffect(() => {
    setValue("email", email);

    if (occurrenceType !== "metastatic") {
      setValue("primary_occurrence_location_for_metastatic", "");
    }
  }, [email, occurrenceType]);

  const { leavePage }  = useUser();

  return (
    <Grid
      item
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
    >
      <PatternPrompt
        when={isDirty}
        message={"You are about to leave the enrollment process. Data will be lost."}
        onProceed={leavePage}
      />
      <form
        onSubmit={handleSubmit(onSubmit)}
        autoComplete="off"
        noValidate
      >
        <Grid
          item
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid
            item
            container
            justifyContent="center"
            alignItems="center"
            xs={10}
            md={8}
            xl={5}
          >

            {/*First Name*/}
            <Grid item container className={formField}>
              <Controller
                name="first_name"
                control={control}
                render={({ field, fieldState: { error } }) =>
                  <PatternTextField
                    variant="standard"
                    color="primary"
                    label="First Name"
                    error={!!error}
                    fullWidth
                    helperText={error ? error.message : ""}
                    required
                    inputProps={{
                      "data-testid": "first-name",
                    }}
                    {...field}
                  />
                }
              />
            </Grid>

            {/*Last Name*/}
            <Grid item container className={formField}>
              <Controller
                name="last_name"
                control={control}
                render={({ field, fieldState: { error } }) =>
                  <PatternTextField
                    variant="standard"
                    color="primary"
                    label="Last Name"
                    fullWidth
                    error={!!error}
                    helperText={error ? error.message : ""}
                    required
                    inputProps={{
                      "data-testid": "last-name",
                    }}
                    {...field}
                  />
                }
              />
            </Grid>

            {/*Phone Number*/}
            <Grid item container className={formField}>
              <PatternPhoneField
                name="phone_number"
                label="Phone Number"
                control={control}
                setValue={setValue}
                setError={setError}
                clearErrors={clearErrors}
                helperText={errors.phone_number ? "Please enter your 10-digit phone number." : ""}
                testId={"phone-number"}
                required
              />
            </Grid>

            {/*Email*/}
            <Grid item container className={formField}>
              <Controller
                name="email"
                control={control}
                render={({ field, fieldState: { error } }) =>
                  <PatternTextField
                    variant="standard"
                    color="primary"
                    label="Email"
                    fullWidth
                    inputProps={{
                      minLength: 1,
                      maxLength: 100,
                      type: "email",
                      "data-testid": "email"
                    }}
                    required
                    error={!!error}
                    helperText={error ? error.message : ""}
                    {...field}
                  />
                }
              />
            </Grid>

            {/*Confirm Email*/}
            <Grid item container className={formField}>
              <Controller
                name="email2"
                control={control}
                render={({ field, fieldState: { error} }) =>
                  <PatternTextField
                    variant="standard"
                    color="primary"
                    label="Confirm Email"
                    fullWidth
                    inputProps={{
                      minLength: 1,
                      maxLength: 100,
                      type: "email",
                      "data-testid": "confirm-email"
                    }}
                    error={!!error}
                    helperText={error ? error.message : ""}
                    required
                    {...field}
                  />
                }
              />
            </Grid>

            {/*Cancer Diagnosis*/}
            <Grid item container className={formField}>
              <PatternSelect
                control={control}
                name="cancer_diagnosis"
                label="Cancer Diagnosis"
                error={!!errors.cancer_diagnosis}
                helperText={errors.cancer_diagnosis ? "You must select a diagnosis." : ""}
                value={cancers}
                required
                fullWidth
                testId={"cancer-diagnosis"}
              >
                {cancers.map(
                  cancer =>
                    <MenuItem
                      key={cancer.id}
                      value={cancer.id}
                    >
                      {cancer.display}
                    </MenuItem>)}
              </PatternSelect>
            </Grid>

            { cancerDiagnosis === 0 && (
              <NotListed/>
            )}

            {/*Tumor or Fluid Location*/}
            <Grid item container className={formField}>
              <Controller
                name="site"
                control={control}
                render={({ field }) =>
                  <PatternTextField
                    variant="standard"
                    color="primary"
                    label="Tumor or Fluid Location"
                    InputProps={{
                      endAdornment: <Tooltip
                        className={tooltip}
                        title="From where in the body is the tumor or fluid being removed?"
                      >
                        <InfoIcon />
                      </Tooltip>
                    }}
                    inputProps={{
                      "data-testid": "site",
                    }}
                    fullWidth
                    {...field}
                  />
                }
              />
            </Grid>

            {/*Occurrence Type*/}
            <Grid item container className={formField}>
              <PatternSelect
                control={control}
                name="occurrence_type"
                label="Occurrence Type"
                fullWidth
                testId={"occurrence-type"}
              >
                <MenuItem value="primary">primary</MenuItem>
                <MenuItem value="recurrent">recurrent</MenuItem>
                <MenuItem value="metastatic">metastatic</MenuItem>
                <MenuItem value="unsure">unsure</MenuItem>
              </PatternSelect>
            </Grid>

            {/*Primary occurrence location for metastatic*/}
            { occurrenceType === "metastatic" &&
              <Grid item container className={formField}>
                <Controller
                  name="primary_occurrence_location_for_metastatic"
                  control={control}
                  render={({ field }) =>
                    <PatternTextField
                      variant="standard"
                      color="primary"
                      label="Where was the primary cancer occurrence?"
                      fullWidth
                      inputProps={{
                        type: "text",
                        "data-testid": "metastatic"
                      }}
                      {...field}
                    />
                  }
                />
              </Grid>
            }

            {/*Country*/}
            <Grid item container className={formField}>
              <PatternSelect
                id="county"
                name="country"
                label="Country"
                control={control}
                fullWidth
                required
                testId={"country-select"}
                error={!!errors.country}
                helperText={errors.country ? "You must select your country." : ""}
              >
                <MenuItem value="USA">USA</MenuItem>
                <MenuItem value="Canada">Canada</MenuItem>
              </PatternSelect>
            </Grid>

            {/*Your Street Address*/}
            <Grid item container className={formField}>
              <Controller
                name="mailing_address_1"
                control={control}
                render={({ field }) =>
                  <PatternTextField
                    variant="standard"
                    color="primary"
                    label="Your Street Address"
                    fullWidth
                    inputProps={{
                      "data-testid": "street-address",
                    }}
                    {...field}
                  />
                }
              />
            </Grid>

            {/*Your Street Address 2 (optional)*/}
            <Grid item container className={formField}>
              <Controller
                name="mailing_address_2"
                control={control}
                render={({ field }) =>
                  <PatternTextField
                    variant="standard"
                    color="primary"
                    label="Your Street Address 2 (optional)"
                    fullWidth
                    inputProps={{
                      "data-testid": "street-address2",
                    }}
                    {...field}
                  />
                }
              />
            </Grid>

            {/*City*/}
            <Grid item container className={formField}>
              <Controller
                name="city"
                control={control}
                render={({ field }) =>
                  <PatternTextField
                    variant="standard"
                    color="primary"
                    label="City"
                    fullWidth
                    inputProps={{
                      "data-testid": "city",
                    }}
                    {...field}
                  />
                }
              />
            </Grid>

            {/*State / Province*/}
            { countrySelected && (
              <Grid item container className={formField}>
                <PatternSelect
                  control={control}
                  name="state"
                  label={countrySelected === "USA" ? "State" : "Province"}
                  fullWidth
                  testId={`state-province-${countrySelected}`}
                >
                  {countrySelected === "USA" && americanStates.map((state, index) =>
                    <MenuItem
                      key={index}
                      value={state}>
                      {state}
                    </MenuItem>
                  )}
                  {countrySelected === "Canada" && canadianProvinces.map((province, index) =>
                    <MenuItem
                      key={index}
                      value={province}>
                      {province}
                    </MenuItem>
                  )}
                </PatternSelect>
              </Grid>
            )}

            {/*Zip / Postcode*/}
            <Grid item container className={formField}>
              <Controller
                name="postcode"
                control={control}
                render={({ field }) =>
                  <PatternTextField
                    variant="standard"
                    color="primary"
                    label={countrySelected === "USA" ? "Zip" : "Postcode"}
                    fullWidth
                    inputProps={{
                      "data-testid": "zip",
                    }}
                    {...field}
                  />
                }
              />
            </Grid>

            <Grid item className={formField}>
              <Box mt={5}>
                <Button
                  data-testid="save-button"
                  type="submit"
                  color="primary"
                  variant="contained"
                  disabled={isEnrollmentPersonalInfoFormInvalid(
                    isValid,
                    cancerDiagnosis,
                    email,
                    confirmEmail,
                    phoneNumber)
                  }
                >
                  Save and Continue
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Grid>

      </form>
    </Grid>
  );
};

YourInfoPersonalInfoForm.propTypes = {
  onSubmit: PropTypes.func,
  email: PropTypes.string,
  cancers: PropTypes.array
};

export default YourInfoPersonalInfoForm;