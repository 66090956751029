import { Box, Grid, Link, useMediaQuery } from "@mui/material";
import { FooterLinksDesktop, FooterLinksMobile, useStyles } from "./index";
import { Link as RouterLink } from "react-router-dom";
import FooterLogo from "assets/images/footer_logo.png";
import PropTypes from "prop-types";

const FooterLinks = ({ showLinks }) => {

  const breakpoint960 = useMediaQuery(theme => theme.breakpoints.down("md"));
  const breakpoint600 = useMediaQuery(theme => theme.breakpoints.down(600));

  const { linkWrapper, link, footerLogo } = useStyles();

  if (!showLinks) {
    return null;
  }

  return (
    <Grid container>
      <Grid
        item
        container
        alignItems="center"
        justifyContent={breakpoint960 ? "center" : "flex-start"}
        sm={12}
        md={8}
      >
        <Box ml={breakpoint960 ? 0 : 3} mb={breakpoint960 ? 3 : 0}>
          { breakpoint600 ?
            <FooterLinksMobile classes={{linkWrapper, link}}/> :
            <FooterLinksDesktop classes={{linkWrapper, link}}/>
          }
        </Box>
      </Grid>
      <Grid
        item
        container
        sm={12}
        md={4}
        alignItems="flex-end"
        justifyContent={breakpoint960 ? "center" : "flex-end"}
      >
        <Box mr={breakpoint960 ? 0 : 3} mt={breakpoint960 ? 3 : 0}>
          <Link component={RouterLink} to="/">
            <img src={FooterLogo} alt="Powered by RCRF" className={footerLogo}/>
          </Link>
        </Box>
      </Grid>
    </Grid>
  );
};

FooterLinks.propTypes = {
  showLinks: PropTypes.bool
};

FooterLinks.defaultProps = {
  showLinks: true
};

export default FooterLinks;