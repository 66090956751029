import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  studyInfo: [],
  status: "idle",
  error: null
};

const studyInfoSlice = createSlice({
  name: "studyInfo",
  initialState,
  reducers: {
    // eslint-disable-next-line no-unused-vars
    getStudyInfo(action) {},
    pending(state) {
      state.status = "loading";
    },
    success(state, action) {
      state.status = "succeeded";
      state.studyInfo = action.payload;
    },
    failure(state, action) {
      state.status = "failed";
      state.error = action.payload;
    }
  }
});

export const {
  getStudyInfo,
  pending,
  success,
  failure
} = studyInfoSlice.actions;
export default studyInfoSlice.reducer;