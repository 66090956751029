import { createSelector } from "@reduxjs/toolkit";

const cancersSelector = state => state.cancers;

export const selectCancers = createSelector(
  cancersSelector,
  cancers => cancers.cancers
);

export const selectStatus = createSelector(
  cancersSelector,
  cancers => cancers.status
);