import * as PropTypes from "prop-types";
import { ContactInformation } from "../../Enrollment/Consent/ContactInformationDialog";
import { Grid, Link, Paper, Typography, useMediaQuery, useTheme } from "@mui/material";
import { SuccessCardColumn } from "../SuccessCardColumn";
import { useState } from "react";
import { useStyles } from "./index";

const SuccessCard = ({ consent }) => {

  const theme = useTheme();
  const isMobile = useMediaQuery(theme => theme.breakpoints.down("md"));
  const [ open, setOpen ] = useState(false);
  const { link, linkHover, successCard } = useStyles();

  // todo conditional return after hook calls because of new react warning
  if (!consent) return null;

  const blue = theme.palette.pattern.blue;
  const green = theme.palette.pattern.green;
  const yellow = theme.palette.pattern.yellow;

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Paper elevation={3} className={successCard}>
        <Grid
          item
          container
          direction={isMobile ? "column" : "row"}
          xs={12}
        >
          <SuccessCardColumn
            color={blue}
            header="What happens next?"
          >
            <>
              <Grid item>
                <Typography paragraph={true} align="center">
                    Pattern&#39;s team will reach out to your doctors to coordinate the secure transfer of your tumor
                    tissue and medical information to the&nbsp;
                  <Link
                    className={link}
                    href={consent?.sponsor?.additionalInfoLink}
                    target="_blank" rel="noreferrer"
                  >
                    {consent?.sponsor?.name}
                  </Link>.
                </Typography>
              </Grid>
              <Grid item>
                <Typography paragraph={true} align="center">
                  You&#39;ve been emailed a signed copy of your consent form.
                </Typography>
              </Grid>
            </>
          </SuccessCardColumn>

          <SuccessCardColumn
            color={green}
            header="What if I have questions?"
          >
            <>
              <Grid item>
                <Typography
                  paragraph={true}
                  align="center"
                >
                    If you have questions about the {consent?.study.name},
                    you can review information&nbsp;
                  <Link
                    className={link}
                    href={consent?.sponsor?.additionalInfoLink}
                    target="_blank"
                    rel="noreferrer"
                  >
                    here
                  </Link> or&nbsp;
                  <Link
                    className={`${link} ${linkHover}`}
                    onClick={handleOpen}
                    rel="noreferrer"
                  >
                    contact the {consent?.sponsor?.name}
                  </Link> directly.
                </Typography>
              </Grid>
              <Grid item>
                <Typography paragraph={true} align="center">
                    You can
                  <Link
                    className={link}
                    href="mailto:info@pattern.org?&subject=Pattern.org%20Question"
                    target="_blank"
                    rel="noreferrer"
                  >
                    &nbsp;contact Pattern&#39;s support team&nbsp;
                  </Link>
                    if you have any
                    questions about this website.
                </Typography>
              </Grid>
            </>
          </SuccessCardColumn>

          <SuccessCardColumn
            color={yellow}
            header="How can I learn more?"
          >
            <Typography paragraph={true} align="center">
                We encourage you to learn more about the scientific research Pattern is supporting
                at the {consent?.sponsor?.name} by visiting&nbsp;
              <Link
                className={link}
                href={consent?.sponsor?.additionalInfoLink}
                target="_blank"
                rel="noreferrer"
              >
                their website
              </Link>.
            </Typography>
          </SuccessCardColumn>
        </Grid>
      </Paper>
      <ContactInformation
        isOpened={open}
        onClose={handleClose}
      />
    </>
  );
};

SuccessCard.propTypes = {
  consent: PropTypes.object
};

export default SuccessCard;