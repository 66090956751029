import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  partnerLogo: {
    maxWidth: "33%",
    height: "auto",
    marginLeft: "auto",
    marginRight: "auto",
    display: "block"
  },
  partnerLogoSmall: {
    maxWidth: "100%",
    maxHeight: "900px",
    [theme.breakpoints.down(600)]: {
      maxWidth: "33%",
      marginLeft: "auto",
      marginRight: "auto",
      display: "block"
    },
  }
}));

export default useStyles;