import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  title: {
    color: theme.palette.pattern.white,
    fontSize: "2rem",
    fontWeight: 500
  },
  videoWrapper: {
    position: "relative",
    paddingBottom: "56.25%" /* 16:9 */,
    height: 0,
    overflow: "hidden"
  },
  aspectRatio16x9: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    border: 0
  },
  button: {
    marginBottom: theme.spacing(10)
  }
}));

export default useStyles;