import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiPaper-root": {
      maxWidth: "80%",
      maxHeight: "80%"
    }
  },
  chip: {
    marginRight: theme.spacing(2),
    backgroundColor: theme.palette.pattern.grayChip,
    color: theme.palette.pattern.white,
    fontSize: "1.3rem",
    fontWeight: 500,
    paddingLeft: "1.2rem",
    paddingRight: "1.2rem",
    "& svg": {
      color: theme.palette.pattern.grayChipText,
      fontSize: "1.3rem",
      fontWeight: 400,
    },
    "& a": {
      textDecoration: "none",
      color: theme.palette.pattern.grayChipText,
    }
  },
  greenChip: {
    backgroundColor: theme.palette.pattern.green,
    "& svg": {
      color: theme.palette.pattern.white,
      fontSize: "1.3rem",
      fontWeight: 400,
    },
    "& a": {
      textDecoration: "none",
      color: theme.palette.pattern.white,
    }
  },
  header: {
    backgroundColor: theme.palette.pattern.blue,
    padding: 0,
    fontSize: "2rem",
    fontWeight: 500,
    color: theme.palette.pattern.white
  },
  content: {
    padding: 0,
    marginBottom: 0
  },
}));

export default useStyles;