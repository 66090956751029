import { PatternCircularProgress } from "shared/components";
import { useConsent, useEnrollmentStepper } from "shared/hooks";
import ConsentView from "./Consent.view";

const ConsentContainer = () => {

  const {
    consentStatus,
    consent,
    currentConsentStep,
    maxConsentStep,
    consentStudyName,
    updateConsentFormBirthday,
    updateConsentStep
  } = useConsent();

  const {
    updateEnrollmentStep
  } = useEnrollmentStepper();

  if (consentStatus !== "succeeded") return <PatternCircularProgress />;

  const handleOnStepClick = index => updateConsentStep({ currentConsentStep: index });

  const handleOnStepMobileClick = (sequence) => {
    const index = consent.steps.map(item => item.sequence).indexOf(sequence);
    handleOnStepClick(index);
  };

  const handleOnClickUnderstand = () => {
    if (maxConsentStep > currentConsentStep) {
      updateConsentStep({ currentConsentStep: currentConsentStep + 1});
    } else {
      updateConsentStep({ currentConsentStep: currentConsentStep + 1, maxConsentStep: currentConsentStep + 1 });
    }
  };

  const handleOnClickUnderstandLastStep = consentFormData => {
    updateConsentFormBirthday(consentFormData.birthday);
    updateEnrollmentStep({ currentEnrollmentStep: 3, maxEnrollmentStep: 3 });
  };

  return (
    <ConsentView
      consent={consent}
      consentStudyName={consentStudyName}
      currentConsentStep={currentConsentStep}
      maxConsentStep={maxConsentStep}
      onStepClick={handleOnStepClick}
      onStepMobileClick={handleOnStepMobileClick}
      onClickUnderstand={handleOnClickUnderstand}
      onClickUnderstandLastStep={handleOnClickUnderstandLastStep}
    />
  );
};

export default ConsentContainer;