import "@fontsource/roboto";
import { IntercomProvider } from "react-use-intercom";
import { Provider } from "react-redux";
import { Root } from "./components";
import { RouterProvider, createBrowserRouter,
} from "react-router-dom";
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import reportWebVitals from "./reportWebVitals";
import theme from "theme";

import { PersistGate } from "reduxjs-toolkit-persist/es/integration/react";
import { createRoot } from "react-dom/client";
import { persistor, store } from "./redux/store";

const container = document.getElementById("root");
const root = createRoot(container);

const router = createBrowserRouter([
  { path: "*", element: <Root/> },
]);

export default function App() {
  return <RouterProvider router={router} />;
}

root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
          <CssBaseline/>
          <IntercomProvider appId={process.env.REACT_APP_INTERCOM_APP_ID} autoBoot>
            <App />
          </IntercomProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </PersistGate>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
