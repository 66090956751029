import { TextField } from "@mui/material";
import { withStyles } from "@mui/styles";

const { makeStyles } = require("@mui/styles");

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: "3.4rem",
    fontWeight: 400,
    color: theme.palette.pattern.blue
  },
  paragraph: {
    fontSize: "2rem",
    fontWeight: 500,
    color: theme.palette.pattern.black,
  },
  questionBtn: {
    cursor: "pointer"
  },
  questionsBtnSub: {
    fontSize: "1.6rem",
    color: theme.palette.pattern.gray
  },
  formWidth: {
    width: "80%"
  },
  radioGroup: {
    justifyContent: "space-around"
  }
}));

const CssTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "#0090D0"
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#0090D0",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#0090D0",
      },
      "&:hover fieldset": {
        borderColor: "#0090D0",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#0090D0",
      }
    },
  },
})(TextField);

export { useStyles,  CssTextField };