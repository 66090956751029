import * as PropTypes from "prop-types";

export const step = PropTypes.shape({
  sequence: PropTypes.string,
  section_title: PropTypes.string,
  text: PropTypes.array,
  accept_button_text: PropTypes.string
});

export const steps = PropTypes.arrayOf(step);

export const sponsor = PropTypes.shape({
  name: PropTypes.string,
  shortName: PropTypes.string,
  additionalInfoLink: PropTypes.string,
  logoUrl: PropTypes.string
});

export const study = PropTypes.shape({
  name: PropTypes.string,
  shortName: PropTypes.string,
  additionalInfoLink: PropTypes.string,
  logoUrl: PropTypes.string,
  description: PropTypes.array
});

export const contact_info = PropTypes.shape({
  phone: PropTypes.string,
  email: PropTypes.string,
  personnel: PropTypes.array
});

export const consent = PropTypes.shape({
  id: PropTypes.number,
  contact_info,
  study,
  sponsor,
  steps,
  amendment_info: PropTypes.string
});