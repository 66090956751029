import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  List,
  ListItem,
  Toolbar,
  Typography
} from "@mui/material";
import { useEffect, useState } from "react";
import { useStyles } from "./index";
import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import PropTypes from "prop-types";

const YourInfoHelpDialog = ({ isOpened, onClose }) => {
  const [open, setOpen] = useState(false);

  const style = {
    display: "inline-block"
  };

  const Chips = () => (
    <Box mb={3}>
      <Box mb={1} style={style}>
        <Chip
          label="Click to live chat"
          icon={<ChatBubbleIcon color="white"/>}
          className={chip}
        />
      </Box>

      <Box mb={1} style={style}>
        <Chip
          label={<a href="tel:(919) 827-8373">Call us at (919) 827-8373</a>}
          icon={<PhoneIcon color="white"/>}
          className={chip}
        />
      </Box>

      <Box mb={1} style={style}>
        <Chip
          label={<a href="mailto:info@pattern.org">Send an email to info@pattern.org</a>}
          icon={<EmailIcon color="white"/>}
          className={chip}
        />
      </Box>

    </Box>
  );

  const {
    root,
    header,
    paragraph,
    subParagraph,
    chip,
    list,
    content,
    paragraphTitle,
    footer
  } = useStyles();

  useEffect(() => {
    setOpen(isOpened);
  }, [isOpened]);

  return (
    <Dialog
      data-testid="your-help-dialog"
      open={open}
      onClose={onClose}
      maxWidth="xl"
      className={root}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" className={header}>
        <Toolbar color="primary">
          Enrollment Help
        </Toolbar>
      </DialogTitle>
      <DialogContent className={content}>
        <List className={list}>
          <ListItem>
            <Typography variant="body2" className={paragraph}>
                Pattern.org allows you to directly donate your tumor tissue to rare cancer research.
                The information below covers some of our most frequently asked questions,
                but there are also three easy ways you can contact us for more:
            </Typography>
          </ListItem>
          <ListItem>
            <Chips/>
          </ListItem>
          <Box mb={3}>
            <Divider/>
          </Box>

          <ListItem>
            <Typography variant="h3" className={paragraphTitle}>
              <ChevronRightIcon/>
                Rare Cancer Researchers Need Tissue
            </Typography>
          </ListItem>
          <ListItem>
            <Typography variant="body2" className={subParagraph}>
                By contributing your tumor tissue, researchers will seek to make a working copy of your
                cancer that will continually grow in a laboratory dish. This copy is called a &#8220;model&#8220;;
                it can be used to study cancer, including the genetic changes that occurred.
            </Typography>
          </ListItem>
          <ListItem>
            <Typography variant="body2" className={subParagraph}>
                In the future, when researchers better understand cancer,
                doctors may use cancer models to customize cancer treatments based on each patient’s
                unique genetic makeup. Establishing and sharing the models developed by this early
                research project is the first step in making that kind of progress.
            </Typography>
          </ListItem>

          <ListItem>
            <Typography variant="h3" className={paragraphTitle}>
              <ChevronRightIcon/>
                Your Tumor Donation Makes a Difference
            </Typography>
          </ListItem>
          <ListItem>
            <Typography variant="body2" className={subParagraph}>
                Pattern.org differs from hospitals who collect tumor tissue for research in some important ways:
            </Typography>
          </ListItem>
          <ListItem>
            <Typography variant="body2" className={subParagraph}>
              <span>We Share:</span> Unfortunately, not all hospitals collaborate with scientists to ensure your
                tissue makes the biggest impact. We make all successful models openly available to the cancer
                research community.
            </Typography>
          </ListItem>
          <ListItem>
            <Typography variant="body2" className={subParagraph}>
              <span>Now, Not Later:</span> We get your tissue to labs for immediate use rather than storing
                that tissue for the future.
            </Typography>
          </ListItem>
          <ListItem>
            <Typography variant="body2" className={subParagraph}>
              <span>Anywhere, Anytime:</span> Not all hospitals offer tissue donation. With Pattern.org,
                you can donate your tissue with any provider, at any hospital, at no additional cost to you.
            </Typography>
          </ListItem>

          <ListItem>
            <Typography variant="h3" className={paragraphTitle}>
              <ChevronRightIcon/>
                Enroll in Just Four Steps
            </Typography>
          </ListItem>
          <ListItem>
            <Typography variant="body2" className={subParagraph}>
              <span>Step 1. (this one)</span>  Register by providing basic contact information
                and your cancer diagnosis. (5 minutes)
            </Typography>
          </ListItem>
          <ListItem>
            <Typography variant="body2" className={subParagraph}>
              <span>Step 2.</span> We match you to a research project. Read a short description about the
                project&apos;s sponsors and goals. (5 minutes)
            </Typography>
          </ListItem>
          <ListItem>
            <Typography variant="body2" className={subParagraph}>
              <span>Step 3.</span> You will be asked to read and electronically sign the consent document.
                This part is especially important: it reviews your rights and allows the Pattern.org team to
                facilitate your participation. (5-10 minutes)
            </Typography>
          </ListItem>
          <ListItem>
            <Typography variant="body2" className={subParagraph}>
              <span>Step 4.</span> Provide a few more details about your clinical care. In order to help us
                successfully transport your donation, we’ll need to know about your surgery date and healthcare
                providers. We will work with your doctors to transfer the tissue to the research lab. (5 minutes)
            </Typography>
          </ListItem>

          <ListItem>
            <Typography variant="h3" className={paragraphTitle}>
              <ChevronRightIcon/>
                Ready to Start?
            </Typography>
          </ListItem>
          <ListItem>
            <Typography variant="body2" className={subParagraph}>
                Scientists need tumor tissue like yours to study cancer and develop future treatments.
                If you&apos;re ready to get started, close this panel by clicking the &#8220;CLOSE&#8220; button,
                then continue the process by filling out the &#8220;Your Information&#8220; section.
            </Typography>
          </ListItem>
          <ListItem>
            <Typography variant="body2" className={subParagraph}>
                Still have questions? Scroll to the top of the page for three easy ways to get in touch with us.
                We&apos;re always happy to hear from you.
            </Typography>
          </ListItem>
        </List>

      </DialogContent>
      <DialogActions className={footer}>
        <Button
          color="inherit"
          data-testid="enrollment-dialog-close"
          size="large"
          onClick={onClose}
        >
            Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

YourInfoHelpDialog.propTypes = {
  isOpened: PropTypes.bool,
  onClose: PropTypes.func.isRequired
};

YourInfoHelpDialog.defaultProps = {
  isOpened: false,
};

export default YourInfoHelpDialog;