import {
  Button,
  Drawer,
  IconButton,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { useState } from "react";
import { useStyles } from "./index";
import MenuIcon from "@mui/icons-material/Menu";
import PropTypes from "prop-types";

const HeaderMenuMobile = ({
  isDashboardPage,
  isEnrollmentPage,
  isFaqPage,
  isAboutPage,
  isWhyDonatePage,
  sessionPersonalInfo,
  onLogout
}) => {

  const { root, burger, btn, btnWhyDonate, btnAboutFaq } = useStyles();

  const [state, setState] = useState({
    top: false
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const handleLogout = () => {
    toggleDrawer("top", false);
    onLogout();
  };

  return (
    <div className={root}>
      <IconButton
        onClick={toggleDrawer("top", true)}
        color="secondary"
        className={burger}
        size="large">
        <MenuIcon fontSize="large"/>
      </IconButton>
      <Drawer
        variant="temporary"
        anchor="top"
        open={state["top"]}
        onClose={toggleDrawer("top", false)}
      >
        { !isDashboardPage && (
          !isEnrollmentPage ?  (
            <>
              { !isWhyDonatePage &&
                  <Button
                    className={`${btn} ${btnWhyDonate}`}
                    fullWidth
                    disableElevation
                    onClick={toggleDrawer("top", false)}
                    component={RouterLink}
                    to="/whyDonate"
                  >
                    Why donate tissue?
                  </Button>
              }

              {!isAboutPage &&
                  <Button
                    className={`${btn} ${btnAboutFaq}`}
                    fullWidth
                    disableElevation
                    onClick={toggleDrawer("top", false)}
                    component={RouterLink}
                    to="/about"
                  >
                    About
                  </Button>
              }

              {!isFaqPage &&
                  <Button
                    className={`${btn} ${btnAboutFaq}`}
                    fullWidth
                    disableElevation
                    onClick={toggleDrawer("top", false)}
                    component={RouterLink}
                    to="/faq"
                  >
                    FAQ
                  </Button>
              }
            </>
          ) : (
            <Button
              color="secondary"
              fullWidth
              disableElevation
              className={btn}
              onClick={toggleDrawer("top", false)}
              component={RouterLink}
              to="/"
            >
                Go Back
            </Button>
          )
        )}


        { sessionPersonalInfo ? (
          <Button
            variant="contained"
            color="secondary"
            fullWidth
            className={btn}
            disableElevation
            onClick={handleLogout}
          >
            Logout
          </Button>
        ) : (
          <Button
            variant="contained"
            color="secondary"
            fullWidth
            className={btn}
            disableElevation
            onClick={toggleDrawer("top", false)}
            component={RouterLink}
            to="/login"
          >
            Login
          </Button>
        )}

      </Drawer>
    </div>
  );
};

HeaderMenuMobile.propTypes = {
  isDashboardPage: PropTypes.bool.isRequired,
  isEnrollmentPage: PropTypes.bool.isRequired,
  isFaqPage: PropTypes.bool.isRequired,
  isAboutPage: PropTypes.bool.isRequired,
  isWhyDonatePage: PropTypes.bool.isRequired,
  sessionPersonalInfo: PropTypes.object,
  onLogout: PropTypes.func
};

HeaderMenuMobile.defaultTypes = {
  isDashboardPage: false,
  isEnrollmentPage: true,
  isFaqPage: false,
  isAboutPage: false,
  isWhyDonatePage: false,
  sessionPersonalInfo: null
};

export default HeaderMenuMobile;