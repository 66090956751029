import { Button, Grid, Typography, useMediaQuery } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { useStyles } from "./index";
import { useUser } from "shared/hooks";

const JumbotronCard = () => {

  const { root, title, paragraph, button } = useStyles();
  const isExtraSmall = useMediaQuery(theme => theme.breakpoints.down("sm"));

  const { sessionPersonalInfo} = useUser();

  return (
    <Grid
      className={root}
      item
      container
      xs={10}
      md={5}
      direction="column"
      alignContent="center"
      justifyContent="space-between"
      spacing={isExtraSmall ? 1 : 2}
    >
      <Grid item xs>
        <Typography className={title} variant="h1">
          Your Surgery or Fluid Drain Can Advance Cancer Research
        </Typography>
      </Grid>
      <Grid item xs>
        <Typography className={paragraph} variant="h2">
          With just 10 minutes, you can make sure your tumor tissue is sent to top cancer researchers.
        </Typography>
      </Grid>
      <Grid item xs>
        <Button
          className={button}
          variant="contained"
          color="secondary"
          component={RouterLink}
          to="/enrollment"
        >
          { sessionPersonalInfo ? "Continue Enrollment" : "Get Started" }
        </Button>
      </Grid>
    </Grid>
  );
};

export default JumbotronCard;