import { GET_STUDY } from "shared/constants/endpoints";
import axios from "axios";

export class StudyInfoService {
  static async getStudyList(cancer_id) {
    try {
      const res = await axios.get(GET_STUDY, { params: { cancer_id } });
      return res.data;
    } catch (error) {
      throw error;
    }
  }
}