import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography
} from "@mui/material";
import { useEffect, useState } from "react";
import { useStyles } from "./index";
import PropTypes from "prop-types";

const YourInfoErrorDialog = ({ isOpened, onClose, email, error, clearErrors }) => {
  const [open, setOpen] = useState(false);

  const { root, title, paragraph } = useStyles();

  useEffect(() => {
    setOpen(isOpened);
  }, [isOpened]);

  const emailErrorMsg =
    `An account already exists for email ${email}. Use the LOGIN button to access your existing account.`;

  const handleClose = () => {
    clearErrors();
    setOpen(false);
  };

  const errorStatus = error?.response.status;

  return (
    <Dialog
      data-testid="your-info-error-dialog"
      open={open}
      onClose={onClose}
      maxWidth="xl"
      className={root}
      aria-labelledby="your-info-error-dialog"
      aria-describedby="your-info-error-dialog-description"
    >
      <DialogTitle id="your-info-error-dialog-title" className={title}>
        An error occurred with your submission:
      </DialogTitle>
      <DialogContent>
        <Typography variant="subtitle1" className={paragraph}>
          {errorStatus === 409 && emailErrorMsg}
          {errorStatus === 400 && error.message}
        </Typography>
        {errorStatus === 400 && (
          <Typography variant="subtitle1" className={paragraph}>
            Correct the problem and press [SAVE AND CONTINUE] to proceed.
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={errorStatus === 409 ? onClose : handleClose} color="primary" size="large" autoFocus>
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};

YourInfoErrorDialog.propTypes = {
  clearErrors: PropTypes.func,
  error: PropTypes.object,
  isOpened: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  email: PropTypes.string,
};

YourInfoErrorDialog.defaultProps = {
  isOpened: false,
};

export default YourInfoErrorDialog;