import * as PropTypes from "prop-types";
import {
  Box,
  Button,
  Grid,
  Typography
} from "@mui/material";
import { ContactInfo, CurrentEnrollments } from "./index";
import { useStyles } from "../Success";

const DashboardView = ({ userData, onSubmit, onClick, hasNoAdditionalStudies }) => {
  const {
    title,
    subTitle
  } = useStyles();

  const { personal, cases } = userData;

  const { cancer_diagnosis: { id } } = personal;

  return (
    <Grid
      item
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
    >
      <Grid
        item
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={12}>
          <Box mt={3} mb={3}>
            <Typography variant="h1" className={title}>
              Account
            </Typography>
          </Box>
        </Grid>
      </Grid>

      <ContactInfo personal={personal} onSubmit={onSubmit}/>

      { cases.length > 0 && (
        <>
          <Typography variant="h2" className={subTitle}>
              Current Enrollments
          </Typography>
          {cases.map((caseEl, index) => <CurrentEnrollments key={index} singleCase={caseEl}/>)}
        </>
      )}

      { !hasNoAdditionalStudies && (
        <Box mt={4} mb={2}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => onClick(id)}
          >
              View additional studies
          </Button>
        </Box>
      )}

    </Grid>
  );
};

DashboardView.propTypes = {
  userData: PropTypes.object,
  onSubmit: PropTypes.func,
  onClick: PropTypes.func,
  hasNoAdditionalStudies: PropTypes.bool
};

export default DashboardView;