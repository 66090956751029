import { DONATION_INFO } from "shared/constants/endpoints";
import axios from "axios";

export class DonationInfoService {
  static async saveDonationInfo(donationInfoData) {
    try {
      const res = await axios.post(DONATION_INFO, donationInfoData);
      return res.data;
    } catch (error) {
      throw error;
    }
  }
}