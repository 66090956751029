import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography
} from "@mui/material";
import { useEffect, useState } from "react";
import { useStyles } from "./index";
import PropTypes from "prop-types";

const YourInfoNotEligibleDialog = ({ isOpened, onClose }) => {
  const [open, setOpen] = useState(false);

  const { root, title, paragraph } = useStyles();

  useEffect(() => {
    setOpen(isOpened);
  }, [isOpened]);

  return (
    <Dialog
      data-testid="non-eligible-dialog"
      open={open}
      onClose={onClose}
      maxWidth="xl"
      className={root}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" className={title}>
          Thank you!
      </DialogTitle>
      <DialogContent>
        <Typography variant="subtitle1" className={paragraph}>
              We appreciate your interest in contributing to research via Pattern.org.
              We work together with patients receiving care in the contiguous United States or Canada who expect to have
              a biopsy, surgery, or ascites collection in the next six (6) months.
        </Typography>
        <Typography variant="subtitle1" className={paragraph}>
              Based on your answers, you aren&apos;t currently eligible to participate. If your details change,
              please visit us again to complete this consent.
        </Typography>
        <Typography variant="subtitle1" className={paragraph}>
              For questions, please click &apos;Contact Us&apos; below.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          color="primary"
          size="large"
          href={"mailto:info@pattern.org"}
        >
            Contact us
        </Button>
        <Button onClick={onClose} color="primary" size="large" autoFocus>
            Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};

YourInfoNotEligibleDialog.propTypes = {
  isOpened: PropTypes.bool,
  onClose: PropTypes.func.isRequired
};

YourInfoNotEligibleDialog.defaultProps = {
  isOpened: false,
};

export default YourInfoNotEligibleDialog;