import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.pattern.blue
  },
  title: {
    fontSize: "3.4rem",
    fontWeight: 400,
    color: theme.palette.pattern.white
  },
  subtitle: {
    color: theme.palette.pattern.yellow,
    fontSize: "2.4rem",
    fontWeight: 700,
    "& span": {
      fontWeight: 900
    }
  }
}));

export default useStyles;