import { Button, Grid } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { useStyles } from "./index";
import PropTypes from "prop-types";

const HeaderMenuDesktop = ({
  isDashboardPage,
  isEnrollmentPage,
  sessionPersonalInfo,
  isFaqPage,
  isAboutPage,
  isWhyDonatePage,
  onLogout
}) => {

  const { btnWhyDonate, btnLogin, btnAboutFaq } = useStyles();

  return (
    <>
      { !isDashboardPage && (
        !isEnrollmentPage ? (
          <>
            {!isWhyDonatePage &&
                <Grid item>
                  <Button
                    className={btnWhyDonate}
                    component={RouterLink}
                    to="/whyDonate"
                  >
                    Why donate tissue?
                  </Button>
                </Grid>
            }

            {!isAboutPage &&
                <Grid item>
                  <Button
                    className={btnAboutFaq}
                    component={RouterLink}
                    to="/about"
                  >
                    About
                  </Button>
                </Grid>
            }
            {!isFaqPage &&
                <Grid item>
                  <Button
                    className={btnAboutFaq}
                    component={RouterLink}
                    to="/faq"
                  >
                    FAQ
                  </Button>
                </Grid>
            }
          </>
        ) : (
          <Grid item>
            <Button
              color="secondary"
              component={RouterLink}
              to="/"
            >
            Go Back
            </Button>
          </Grid>
        ))
      }

      { sessionPersonalInfo ? (
        <Grid item>
          <Button
            variant="contained"
            color="secondary"
            className={btnLogin}
            onClick={onLogout}
          >
            Logout
          </Button>
        </Grid>
      ) : (
        <Grid item>
          <Button
            variant="contained"
            color="secondary"
            className={btnLogin}
            component={RouterLink}
            to="/login"
          >
            Login
          </Button>
        </Grid>
      )}
    </>
  );
};

HeaderMenuDesktop.propTypes = {
  isDashboardPage: PropTypes.bool.isRequired,
  isEnrollmentPage: PropTypes.bool.isRequired,
  isFaqPage: PropTypes.bool.isRequired,
  isAboutPage: PropTypes.bool.isRequired,
  isWhyDonatePage: PropTypes.bool.isRequired,
  sessionPersonalInfo: PropTypes.object,
  onLogout: PropTypes.func
};

HeaderMenuDesktop.defaultTypes = {
  isDashboardPage: false,
  isEnrollmentPage: true,
  isFaqPage: false,
  isAboutPage: false,
  isWhyDonatePage: false,
  sessionPersonalInfo: null
};

export default HeaderMenuDesktop;